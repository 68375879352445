// function to validate a email structure
import Swal from "sweetalert2";
export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
export const message = (icon, title, text) => {
  Swal.fire({
    icon,
    title,
    text,
  });
};

export const isEmptyOrUndefined = (value) => {
  if (
    value === undefined ||
    value === null ||
    value === "" ||
    value === NaN ||
    value === -1
  ) {
    return true;
  } else {
    return false;
  }
};
