import GenericTableNew from "../Layouts/GenericTableNew";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import paginationStyles from "../Layouts/pagination.module.scss";
import style from "../TableProviders/providers.module.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import poStyles from "./VendorPurchaseOrder.module.scss";
import Lupa from "../../assets/img/icons/lupa.svg";
import yellowCircle from "../../assets/img/icons/yellowCircle.svg";
import redCircle from "../../assets/img/icons/redCircle.svg";
import greenCircle from "../../assets/img/icons/greenCircle.svg";
import watch from "../../assets/img/icons/watch.svg";
import blueWatch from "../../assets/img/icons/Reloj.svg";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import GrantedHands from "../../assets/img/icons/GrantedHands.svg";
import moment from "moment";
import ModalNew from "../Layouts/ModalNew";
import { getPurchaseOrders } from "../../actions/purchaseOrderActions";
import { isEmptyOrUndefined, message } from "../../helpers/helpers";
import Loader from "react-loader-spinner";
function VendorPurchaseOrder(props) {
    const counter = useSelector((state) => state);
    const token = counter.providerReducer?.Authorization;
    // const myPermission =
    //     counter.loginReducer.currentAccount?.profile?.permission?.find(
    //         (x) => x.functionality?.prefix === "StatusPO"
    //     );
    const [result, setResult] = useState({
    })
    const [details, setDetails] = useState({
        detail: {},
        agreement: {},
    })
    const [isLoading, setIsLoading] = useState(false)
    const [filters, setFilters] = useState({
        page: 1,
        perpage: 10,
        status: "",
        provider: counter.providerReducer?.providerSession[0]?.id,
        search: "",
        dateCreated: "",
        code: "",
        name: "",
    });
    const [trigger, setTrigger] = useState(1);
    const getDetOrAgreem = async (props) => {
        setIsLoading(true)
        const res = props?.getDetails ? await getPurchaseOrders({ id: props.getDetails }, token)
            : props?.getAgreement &&
            await getPurchaseOrders(
                { agreement: props.getAgreement?.agreement, provider: props.getAgreement?.provider },
                token
            )
        if (res?.success) {
            setIsLoading(false)
            props?.getDetails && setDetails({ ...details, detail: res?.results })
            props?.getAgreement && setDetails({ ...details, agreement: res?.results })
        } else {
            setIsLoading(false)
            console.log("error")
        }

    };
    const getInitialPOlist = async () => {
        setIsLoading(true)
        const res = await getPurchaseOrders(
            filters,
            token
        );
        if (res?.success) {
            setIsLoading(false)
            setResult(res)
        } else {
            setIsLoading(false)
            return message('error', 'Error', res?.message)
        }
    }
    useEffect(() => {
        getInitialPOlist()
    }, [filters, trigger]);
    const [modalControl, setModalControl] = useState({
        detail: false,
        agreement: false,
        deliveries: false,
    })
    const headerPO = [
        <th key={`PO1`}>No. OC</th>,
        <th key={`PO2`}>Condición de pago</th>,
        <th key={`PO3`}>Fecha entrega</th>,
        <th key={`PO4`}>Valor</th>,
        <th key={`PO5`}>Entregas parciales</th>,
        <th key={`PO5`}>No. Artículos</th>,
        <th key={`PO5`}>Cant. Productos</th>,
        <th key={`PO5`}>Estado</th>,
        <th key={`PO6`}>&nbsp;</th>,
        <th key={`PO6`}>&nbsp;</th>,
    ];
    const headersDetail = [
        <th key={`PO1`}>Descripción del producto</th>,
        <th key={`PO2`}>Código</th>,
        <th key={`PO3`}> Cantidad</th>,
        <th key={`PO4`}>Entrega parcial</th>,
    ];
    const headersAgreement = [
        <th key={`PO1`}>#</th>,
        <th key={`PO11`}>Descripción del producto</th>,
        <th key={`PO2`}>Marca</th>,
        <th key={`PO3`}>Precio Unt.</th>,
        <th key={`PO4`}>Cant.</th>,
        <th key={`PO4`}>Descuento</th>,
        <th key={`PO4`}>Valor Total</th>,
    ];
    const headersPartialDeliveries = [
        <th key={`PO1`}>#</th>,
        <th key={`PO11`}>Fecha</th>,
        <th key={`PO2`}>Cantidad</th>,
    ];
    const renderPO = () => {
        let table = [];
        if (Array.isArray(result?.results)) {
            table = result.results?.map((x, realindex) => {
                return (
                    <tr key={"renderPOStatus" + realindex} className="hover-table-row">
                        <td>{x.purchaseOrderNumber}</td>
                        <td>{x.payConditions}</td>
                        <td>
                            {x.deliveryDate}
                        </td>
                        <td className="text-center">{x.total}</td>
                        <td className="text-center">{x.partialDeliveries || "-"}</td>
                        <td className="text-center">{x.articleQuantity}</td>
                        <td className="text-center">{x.articleQuantity}</td>
                        <td className="text-center ">{
                            x.status === "rejected" || x.status === "disabled" || x.status === "pending" ?
                                <img alt="Rechazado" title="Rechazado" src={redCircle} ></img> :
                                x.status === "solved" || x.status === "enabled" ? <img title="Aprobado" alt="approved" src={greenCircle} ></img> :
                                    <img alt="Pending" title="Pendiente" src={yellowCircle} ></img>
                        }</td>
                        <td className="text-center"
                            style={{ cursor: 'pointer' }} alt="grantedHands" >
                            {!isEmptyOrUndefined(x.agreement) && <img
                                onClick={() => {
                                    !isEmptyOrUndefined(x.agreement) &&
                                        getDetOrAgreem({ getAgreement: x })
                                    setModalControl({ ...modalControl, agreement: true })
                                }}
                                src={GrantedHands}></img>}
                        </td>
                        <td
                            onClick={() => {
                                getDetOrAgreem({ getDetails: x.id })
                                setModalControl({ ...modalControl, detail: true })
                            }
                            }
                            className="text-center cursorPointer">
                            <img style={{ cursor: 'pointer' }} alt="filter" src={Filtrar}></img>
                        </td>
                    </tr >
                );
            });
        }
        return table;
    };
    const renderDetailPo = () => {
        let table = [];
        if (Array.isArray(details?.detail?.articles)) {
            table = details.detail?.articles?.map((x, realindex) => {
                return (
                    <tr key={"renderPOStatus" + realindex} className="hover-table-row">
                        <td>{x.description || "-"}</td>
                        <td className="text-center">{x.id}</td>
                        <td className="text-center">{x.qty_article}</td>
                        <td className="text-center">
                            {
                                x.deliveries?.length > 0 ?
                                    <img
                                        onClick={() => {
                                            setModalControl({ ...modalControl, deliveries: true, detail: false })
                                            setDetails({ ...details, deveriesDetail: x })
                                        }}
                                        alt="watch"
                                        style={{ cursor: 'pointer' }}
                                        src={blueWatch}
                                    >
                                    </img> :

                                    <img
                                        alt="watch"
                                        src={watch}
                                    >
                                    </img>
                            }
                        </td>
                    </tr >
                );
            });
        }
        return table;
    };
    const renderAgreementPo = () => {
        let table = [];
        if (Array.isArray(details?.agreement?.articles)) {
            table = details.agreement?.articles?.map((x, realindex) => {
                return (
                    <tr
                        key={"renderPOStatus" + realindex} className="hover-table-row">
                        <td>{realindex + 1}</td>
                        <td>{x.description || "-"}</td>
                        <td className="text-center">{x.brand}</td>
                        <td className="text-center">{x.unit_price || "-"}</td>
                        <td className="text-center">{x.qty_article || "-"}</td>
                        <td className="text-center">{x.discount_value || "-"}</td>
                        <td className="text-center">{x.totalValue || "$0"}</td>

                    </tr >
                );
            });
        }
        return table;
    };
    const renderPartialDeliveries = () => {
        let table = [];
        if (Array.isArray(details.deveriesDetail?.deliveries)) {
            table = details.deveriesDetail?.deliveries?.map((x, realindex) => {
                return (
                    <tr
                        key={"renderPOStatus" + realindex} className="hover-table-row">
                        <td>{realindex + 1}</td>
                        <td>{moment(x.delivery_date).format('DD/MM/YYYY') || "-"}</td>
                        <td className="text-center">{x.qty_article}</td>
                    </tr >
                );
            });
        }
        return table;
    };
    return (
        <>
            {(isLoading) &&
                <div className="loading">
                    <Loader type="Oval" color="#003f80" height={100} width={100} />
                </div>
            }
            {/* MODAL DETAIL */}
            <ModalNew
                title={details.detail?.purchaseOrder_number}
                show={modalControl.detail}
                btnNoName={"Cancelar"}
                btnNoEvent={() => setModalControl({ ...modalControl, detail: false })}
                size={"xl"}
                onHide={() => {
                    setDetails({
                        detail: {},
                        agreement: {},
                    })
                    setModalControl({ ...modalControl, detail: false })
                }}>
                <div>
                    <div className="row justify-content-between w-100">
                        <div className="d-flex flex-column col">
                            <label htmlFor="due_date" className={style.labelFont}>Fecha de vencimiento</label>
                            <input name="due_date" className={`text-primary fw-bold ${style.selectInputClear}`} readOnly value={moment(details?.detail?.delivery_date).format("YYYY/MM/DD")} />
                        </div>
                        <div className="d-flex flex-column col-2">
                            <label htmlFor="qty_articles" className={style.labelFont}>No. de artículos</label>
                            <input
                                type="number" min="0" className={`text-primary fw-bold ${style.selectInputClear}`}
                                value={details?.detail?.articleQuantity}
                                readOnly />
                        </div>
                        <div className="d-flex flex-column col">
                            <label htmlFor="client" className={style.labelFont}>Cliente</label>
                            <input type="text" name="client" className={`text-primary ${style.selectInputClear}`} value={details?.detail?.client || "-"} readOnly />
                        </div>
                        <div className="d-flex flex-column col">
                            <label htmlFor="terms" className={style.labelFont}>Términos y condiciones</label>
                            <input type="text" name="terms" className={`${style.selectInputClear} bg-disabled-input text-secondary text-darkGray`} readOnly value={details?.detail?.terms} />
                        </div>
                    </div>
                    <h6 className="text-primary fw-bold mt-3">Programación solicitada</h6>
                    <GenericTableNew dark={false} headers={headersDetail}>
                        {renderDetailPo()}
                    </GenericTableNew>
                    <div className="row justify-content-between w-100">
                        <div className="d-flex flex-column col">
                            <label htmlFor="due_date" className={style.labelFont}>Artículos seleccionados</label>
                            <input name="due_date" className={`text-primary fw-bold ${style.selectInputClear}`} readOnly value={moment(details?.detail?.delivery_date).format("YYYY/MM/DD")} />
                        </div>
                        <div className="d-flex flex-column col">
                            <label htmlFor="due_date" className={style.labelFont}>Observaciones</label>
                            <textarea
                                style={{ height: '1rem' }}
                                className={`text-primary fw-bold ${style.selectInputClear}`}
                                readOnly
                                value={details.detail?.observations || "-"}
                            />
                        </div>
                    </div>
                </div>
            </ModalNew>
            {/* MODAL AGREEMENT */}
            <ModalNew
                title={details.agreement?.name}
                subtitle={details.agreement?.client}
                show={modalControl.agreement}
                btnNoName={"Cancelar"}
                btnNoEvent={() => {
                    setDetails(
                        {
                            detail: {},
                            agreement: {},
                        }
                    )
                    setModalControl({ ...modalControl, agreement: false })
                }}
                size={"xl"}
                onHide={() => setModalControl({ ...modalControl, agreement: false })}>
                <div>
                    <div className="row justify-content-between w-50">

                        <div className="d-flex flex-column col">
                            <label htmlFor="due_date" className={style.labelFont}>Vigencia desde:</label>
                            <input name="due_date" className={`text-primary fw-bold ${style.selectInputClear}`} readOnly value={moment(details.agreement?.valid_from).format('DD/MM/YYYY') || "-"} />
                        </div>
                        <div className="d-flex flex-column col">
                            <label htmlFor="due_date" className={style.labelFont}>Hasta</label>
                            <input name="due_date" className={`text-primary fw-bold ${style.selectInputClear}`} readOnly value={moment(details.agreement?.valid_until).format('DD/MM/YYYY') || "-"} />
                        </div>
                    </div>

                    <div className="row justify-content-between w-100">
                        <div className="d-flex flex-column col">
                            <label htmlFor="due_date" className={style.labelFont}>No. de cotización</label>
                            <input name="due_date" className={`text-primary fw-bold ${style.selectInputClear}`} readOnly value={details.agreement?.quotation_number || "-"} />
                        </div>
                        <div className="d-flex flex-column col-2">
                            <label htmlFor="qty_articles" className={style.labelFont}>Validéz</label>
                            <input type="text" min="0" className={`text-primary fw-bold ${style.selectInputClear}`} value={details.agreement?.valid_until || "-"} readOnly />
                        </div>
                        <div className="d-flex flex-column col">
                            <label htmlFor="client" className={style.labelFont}>Términos y condiciones</label>
                            <input type="text" title={details.agreement?.terms} name="client" className={`text-primary ${style.selectInputClear}`} value={details.agreement?.terms || "-"} readOnly />
                        </div>
                        <div className="d-flex flex-column col">
                            <label htmlFor="pay_conditions" className={style.labelFont}>Condiciones de pago</label>
                            <input type="text" name="pay_conditions" className={`${style.selectInputClear} bg-disabled-input text-secondary text-darkGray`} readOnly value={details.agreement?.pay_conditions || "-"} />
                        </div>
                    </div>
                    <h6 className="text-primary fw-bold mt-3">Programación solicitada</h6>
                    <GenericTableNew dark={false} headers={headersAgreement}>
                        {renderAgreementPo()}
                    </GenericTableNew>
                    <div className="row justify-content-between w-100">
                        <div className="d-flex flex-column col">
                            <label htmlFor="due_date" className={style.labelFont}>Observaciones</label>
                            <textarea type="date" name="due_date" className={`text-primary fw-bold ${style.selectInputClear}`} readOnly value={details.agreement?.observations || "-"} />
                        </div>

                    </div>
                </div>
            </ModalNew>
            {/* MODAL PARTIAL DELIVERIES */}
            <ModalNew
                title={"Entregas parciales"}
                show={modalControl.deliveries}
                btnNoName={"Cancelar"}
                btnNoEvent={() => {
                    setDetails(
                        {
                            ...details,
                            agreement: {},
                            deliveries: {},
                        }
                    )
                    setModalControl({ ...modalControl, deliveries: false, detail: true })
                }}
                size={"xs"}
                onHide={() => {
                    setDetails(
                        {
                            ...details,
                            agreement: {},
                            deliveries: {},
                        }
                    )
                    setModalControl({ ...modalControl, deliveries: false, detail: true })
                }}
            >
                <div>
                    <div className="row justify-content-between w-100">
                        <div className="d-flex flex-column col-8">
                            <label htmlFor="pay_conditions" className={style.labelFont}>Producto</label>
                            <input type="text" name="pay_conditions" className={`${style.selectInputClear} bg-disabled-input text-secondary text-darkGray`} readOnly value={details.deveriesDetail?.description || "-"} />
                        </div>

                        <div className="d-flex flex-column col-4">
                            <label htmlFor="qty_articles" className={style.labelFont}>Código</label>
                            <input type="text" name="pay_conditions" className={`${style.selectInputClear} bg-disabled-input text-secondary text-darkGray`} readOnly value={details.deveriesDetail?.id_article || "-"} />
                        </div>
                        <div className="d-flex flex-column col-8">
                            <label htmlFor="pay_conditions" className={style.labelFont}>Dirección de entrega</label>
                            <input type="text" name="pay_conditions" className={`${style.selectInputClear} bg-disabled-input text-secondary text-darkGray`} readOnly value={details.deveriesDetail?.address || "-"} />
                        </div>

                        <div className="d-flex flex-column col-4">
                            <label htmlFor="qty_articles" className={style.labelFont}>Cantidad</label>
                            <input type="text" name="pay_conditions" className={`${style.selectInputClear} bg-disabled-input text-secondary text-darkGray`} readOnly value={details.deveriesDetail?.qty_article || "-"} />
                        </div>
                    </div>
                    <h6 className="text-primary fw-bold mt-3">Programación solicitada</h6>
                    <GenericTableNew dark={false} headers={headersPartialDeliveries}>
                        {renderPartialDeliveries()}
                    </GenericTableNew>
                </div>
            </ModalNew>
            <div className={tableStyles.container} style={{ padding: "0 6rem" }}>
                <div className={poStyles.container1}>
                    <h1 className={`${tableStyles.title} text-primary`}>Órdenes de compras</h1>
                </div>
                <div className={poStyles.container2}>
                    {/* ---------------------------- ROW 1 ---------------------------------- */}
                    <div className="d-flex">
                        <div className={`${poStyles.inputMarginR}  ${poStyles.Item0}`}>
                            <p className={poStyles.crudModalLabel}>Fecha de entrega</p>
                            <input
                                className={`${poStyles.registerInputs}`}
                                name="date_OC"
                                style={{ color: "#58595B" }}
                                type="date"
                                value={filters.dateCreated}
                                onChange={(e) =>
                                    setFilters({
                                        ...filters,
                                        dateCreated: e.target.value,
                                    })
                                }
                                disabled={false}
                            />
                        </div>

                        <div className={`${poStyles.Item0} mr-2`}>
                            <p className={poStyles.crudModalLabel}>Estado</p>
                            <select
                                name="status"
                                style={{ color: "#58595B" }}
                                className={`${poStyles.selectRegister} w-100`}
                                onChange={(e) =>
                                    setFilters({
                                        ...filters,
                                        status: e.target.value,
                                    })
                                }
                                value={filters.status}
                            >
                                <option value="">-Seleccione-</option>
                                <option value="rejected">Rechazado</option>
                                <option value="partially">Pendiente</option>
                                <option value="solved">Aprobado</option>
                            </select>
                        </div>

                        <div className={``}>
                            <p className={poStyles.crudModalLabel}>No. de Factura o remisión</p>
                            <input
                                className={`${poStyles.Search}`}
                                type="text"
                                style={{ color: "#58595B" }}
                                placeholder="  Número de OC o proveedor..."
                                value={filters.purchaseOrder_number}
                                onChange={(e) =>
                                    setFilters({
                                        ...filters,
                                        purchaseOrder_number: e.target.value,
                                    })
                                }
                                disabled={false}
                            />
                        </div>
                        <div className={`${poStyles.Item}`}>
                            <p className={tableStyles.crudModalLabel}>&nbsp;</p>
                            <img
                                src={Lupa}
                                alt="filtrar"
                                className={`${tableStyles.iconSvg}`}
                                onClick={() => setTrigger(trigger + 1)}
                            />
                        </div>
                    </div>

                    <GenericTableNew dark={true} headers={headerPO}>
                        {renderPO()}
                    </GenericTableNew>
                    <div className={paginationStyles.wrapper}>
                        <p className={`${paginationStyles.paginationText} text-secondary`}>
                            Pag.{" "}
                            {result.results ? filters.page : ""}
                            {" de "}
                            {Math.ceil(result.rowTotal / filters.perpage)
                                ? Math.ceil(
                                    result.rowTotal / filters.perpage
                                )
                                : ""}{" "}
                            ({result.rowTotal} encontrados)
                        </p>
                        <Pagination
                            activePage={filters.page}
                            itemsCountPerPage={filters.perpage}
                            totalItemsCount={result.rowTotal}
                            pageRangeDisplayed={5}
                            onChange={(e) => setFilters({ ...filters, page: e })}
                            itemClassPrev={paginationStyles.itemClassPrev}
                            itemClassNext={paginationStyles.itemClassNext}
                            itemClassFirst={paginationStyles.itemClassFirst}
                            itemClassLast={paginationStyles.itemClassLast}
                            itemClass={paginationStyles.itemClass}
                        />
                    </div>
                </div>
            </div>
        </>);
}
export default VendorPurchaseOrder;