import React, { Component } from "react";
// import Filter from "../../assets/img/icons/filtro.svg";
// import Add from "../../assets/img/icons/agregar.svg";
// import Export from "../../assets/img/icons/exportar.svg";
import Gear from "../../assets/img/icons/engraneGris.svg";
// import GearWhite from "../../assets/img/icons/engraneBlanco.svg";
// import GearGray from "../../assets/img/icons/engraneGris.svg";
// import EditIcon from "../../assets/img/icons/editar.svg";
// import EraseICon from "../../assets/img/icons/canecaGris.svg";
// import DeactiveIcon from "../../assets/img/icons/puntoRojo.svg"
// import ActiveIcon from "../../assets/img/icons/puntoVerde.svg"
// import lupaTransparent from "../../assets/img/icons/lupaTransparent.svg"
import Popup from "reactjs-popup";
//import "reactjs-popup/dist/index.css";
import styles from "./customPopup.module.scss";

function CustomPopupExtend (props) {
    return(
    <Popup
        trigger={
        <button className="bg-transparent border-none">
            <img src={Gear} alt="configuracion" className="icons-gear" />
        </button>
        }
        position="right center"
        styles={styles.popupContent}
    >
        <ul className={`${styles.popUpUl} hoverPointer`}>
            {props.showDetails && (
             <li
                onClick={()=>props.showDetailsEvent()}
                className={styles.popUpShowDetails}
             >
                {props.textDetails ? props.textDetails : "Ver Detalle" }
             </li> 
            )}
            {props.showEnable?
            <li
                onClick={()=>props.enableClickEvent()}
                className={props.isEnabled ? styles.popUpLiActiveRed: styles.popUpLiActive}
            >
                {props.isEnabled? "Deshabilitar" : "Habilitar"}
            </li>
            :null}
            {props.showEdit?
                <li
                    onClick={()=>props.editClickEvent()}
                    className={props.iconUpdate ? styles.popUpLiUpdate : styles.popUpLiEdit}
                >
                    {props.editText ? props.editText : "Editar"}
                </li>
            :null}            

            {/* is you need to delete in popup action */}
            {props.showDelete && (
                <li className={styles.popUpLiDelete} onClick={()=>props.deleteEvent()}>
                    {props.deleteText ? props.deleteText : "Eliminar" }
                </li>
            )}
            
            {props.showAddDataSheet && (
                <li className={styles.popUpLiDataSheet} onClick={()=>props.addDataSheetEvent()}>
                    {props.addDataSheetText ? props.addDataSheetText : "Ficha técnica" }
                </li>
            )}
        </ul>
    </Popup>
    );
}

export default CustomPopupExtend;