import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import logoFocaHorizontal from "../../assets/img/header/foca-logo-horizontal.png";
import userLogo from "../../assets/img/header/user-img.png";
import PhoneIcon from "../../assets/img/icons/telefono.svg";
import EditIcon from "../../assets/img/icons/editar.svg";
import OpenLockIcon from "../../assets/img/icons/candado.svg";
import  mailIcon  from "../../assets/img/icons/sobre.svg";
// import { logOutUser } from "../../actions/loginActions";
// import { showAccountInfo } from "../../actions/headerActions";
// import { updateCellphone, updateUserInfo } from "../../actions/userActions";
//import "./header-style.scss";
import headerAccountIcon from "../../assets/img/header/headerAccountIcon.svg";
import headerIconPhone from "../../assets/img/header/headerIconPhone.svg";
import headerIconMail from "../../assets/img/header/headerIconMail.svg";
import headerIconLocation from "../../assets/img/header/headerIconLocation.svg";
import iconCamera from "../../assets/img/icons/iconCamera.svg";
import logoBiowel from "../../assets/img/icons/bioWel.svg";
import whiteUpArrow from "../../assets/img/icons/whiteUpArrow.svg";
import accountSelectIcon from "../../assets/img/icons/accountSelectIcon.svg";
import styles from "./headerStyle.module.scss";

import ModalNew from "../Layouts/ModalNew";
// import AccountSelectRender from "../AccountSelect/AccountSelectRender";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editProfile: false,
      editPhone:true,
      editPassword:false,
      showHelp:false,
      showAccountSelect: false,
      shownModal: "",
      user:{}
    };
  }

  componentDidMount() {
    this.getInitialState();
  }

componentDidUpdate(prevProps) {
  if (this.props.userData !== prevProps.userData) {
    this.getInitialState()
  }
}

  getInitialState = () => {
    var userData = this.props.userData;
    if (userData) {
      this.setState({
        editProfile:false,
        editPhone:true,
        editPassword:false,
        showInputNewPassword:false,
        showButtons:false,
        showHelp:false,
        shownModal: "",
        user: {
          id:userData.id,
          first_name: userData.first_name,
          first_surname:userData.first_surname,
          second_surname:userData.second_surname,
          email: userData.email,
          cellphone: userData.cellphone,
          password:"",
          newPassword:"",
          newPasswordConfirm:"",
        },
      });
    }
  };
handleToggleModal = (whichone) => {
  let currentstate = this.state;
  currentstate.shownModal = whichone;
  this.setState({
    currentstate
  })
}
handleToggleHelp = () => {
  let currentstate = this.state;
  currentstate.showHelp = !currentstate.showHelp;
  this.setState({
    currentstate
  })
}

  onClickPopUp = () => {
    let showAccount = !this.props.showAccount;
    this.props.showAccountInfo(showAccount);
  };
  onClickLogOut = () => {
    this.props.logOutUser();
  };
  handleToggleProfile = () => {
    this.setState({
      editProfile: !this.state.editProfile,
      showButtons: !this.state.showButtons
    });
  };
  handleTogglePassword = () => {
    this.setState({
      editProfile: false,
      editPassword:!this.state.editPassword,
      showInputNewPassword:!this.state.showInputNewPassword,
      showButtons:!this.state.showButtons
    });
  };

  handleToggleAccount = () => {
    this.setState({
      showAccountSelect: !this.state.showAccountSelect,
    });
  };

  toggleDisabledInput = (e) => {
      const name = e.target.name
      let {editPhone, editPassword, showInputNewPassword} = this.state
      switch (name) {
        case "editPhone":
            editPhone = !this.state.editPhone
            break;
        case "editPassword":
            editPassword = !this.state.editPassword
            showInputNewPassword = !this.state.showInputNewPassword
            break;
        default:
            break;
      }
      this.setState({ editPassword, editPhone, showInputNewPassword})
  }

  handleDisard = () =>{
    //document.getElementById("form-edit-profile").reset();
    this.getInitialState()
  }
  
  onChange = (e) =>{
    const name = e.target.name
    const value = e.target.value
    let { user } = this.state
    user[name] = value
    this.setState({user})
  }

  handleSubmit = (e) =>{
      e.preventDefault();
      this.getInitialState()
      if(this.state.editPassword){
        return this.props.updateUserInfo(this.state.user)
      }else{
        return this.props.updateCellphone(this.state.user)
      }
  }
  
  render() {
    return (
      <div className={`${styles.headerContainer} w-100`}>

        <Link to="/inicio">
          <img
            className={`${styles.focaLogoHeader} my-1 mx-5`}
            src={logoFocaHorizontal}
            alt="logo foca header"
          />
        </Link>

        {this.props.isAuth && (
          <div className="right-side-icons">
            <div 
              className={`${styles.hoverPointer} ${styles.userImgHeaderButton}`}
              onClick={this.onClickPopUp}
            >
              {(!!this.props.currentAccount) && (
                <img
                  className={`${styles.userImgHeaderIcon} `}
                  src={accountSelectIcon}
                  alt="imagen usuario header"
                />
              )}
              <div className={`${styles.userImgHeaderText}`}>
                {this.props.currentAccount?.name}
              </div>
              <img
                className={`${styles.userImgHeader} `}
                src={userLogo}
                alt="imagen usuario header"
              />
            </div>
            {this.props.showAccount && (
              <div className={styles.popUpUserProfile}> 
                {/* <h4 className="text-primary p-3">Mi cuenta</h4> */}
                
                  <img 
                      className={`${styles.thatTinyArrowUpThere}`}
                      src={whiteUpArrow} 
                      alt="ignore meeeee" 
                    />
                
                  <div className="d-flex flex-column mx-auto">
                    <div className={styles.profilepicWrapper}>
                      <img 
                        className={`${styles.profilepic} ${styles.hoverPointer} `}  //w-25 mx-3 my-3 h-25 pb-3
                        src={userLogo} 
                        alt="imagen usuario header" 
                        onClick={this.onClickPopUp} 
                      />
                      <img 
                        className={`${styles.changepfp} ${styles.hoverPointer} `}
                        src={iconCamera} 
                        alt="cambiar imagen" 
                      />
                    </div>
                    
                
                    <h5 className={`${styles.userName} text-capitalize`}>
                      {this.state.user?.first_name} {this.state.user?.first_surname} {this.state.user?.second_surname}
                    </h5>
                    
                    
                    {(!!this.props.currentAccount) && (
                      <h6 className={`${styles.userAccountProfile} text-capitalize`}>
                        {this.props.currentAccount?.name} - {this.props.currentAccount?.profile?.nombre}
                      </h6>
                    )}

                    {/* {!this.state.editProfile && (
                      <span className={`${styles.hoverPointer}`}>
                        {this.state.user.email}
                      </span>
                    )} */}

                    {(this.props.currentAccount) && (
                      // <Link className={`${styles.optionText} ${styles.linkText}`}  to="/accountselect">
                      //   Cambiar Rol y Cuenta
                      // </Link>
                      <span
                        className={`${styles.optionText}  ${styles.hoverPointer}`} 
                        onClick={()=>this.handleToggleModal("ACCOUNT")}
                      >
                        Cambiar Rol y Cuenta
                      </span>
                    )}
                    
                    <span 
                      className={`${styles.optionText}  ${styles.hoverPointer}`} 
                      onClick={()=>this.handleToggleModal("PROFILE")}
                    >
                      Configuración
                    </span>
                  
                  

                    {/* <span 
                      className={`${styles.optionText}  ${styles.hoverPointer}`} 
                      onClick={()=>this.handleToggleModal("PASS")}
                    >
                      Cambiar contraseña
                    </span> */}

                    <span 
                      className={`${styles.optionText} ${styles.hoverPointer}`} 
                      onClick={()=>this.handleToggleModal("HELP")}
                    >
                      Ayuda
                    </span>
                    
                    <span className={`${styles.optionText} ${styles.hoverPointer}`} onClick={this.onClickLogOut}>
                      Cerrar sesión
                    </span>
                    
                  </div>
                </div>
            )}
          </div>
        )}

        {/* Profile info */}
        {this.state.shownModal==="PROFILE" && (
          // <div className="d-flex flex-column mx-3 pb-5">
          <ModalNew
            title="Configuración"
            show={this.state.shownModal==="PROFILE"}
            size="md"
            onHide={this.handleDisard}
            btnYesEvent={this.handleSubmit}
            btnYesName="Guardar"
            btnNoName="Cancelar"
          >
            <div className={`input-group ${styles.inputBlueBorder} mt-3 w-100 mx-auto bg-transparent`}>
              <img src={mailIcon} alt="User icon"className={`input-group-prepend ${styles.inputUserIconEditProfile}`}/>
              <input
                type="email"
                placeholder="USUARIO"
                name="username"
                className={`form-control p-3 border-0 ${styles.inputTextHeader}`}
                value={this.state.user.email}
                // onChange={this.onChange}
                aria-describedby="basic-addon1"
                aria-label="Usuario"
                disabled
              />
            </div>
            <div className={`input-group ${styles.inputBlueBorder} mt-3 w-100 mx-auto bg-transparent ${styles.containerInputHeader}`}>
              <img
                src={PhoneIcon}
                alt="User icon"
                className={`input-group-prepend ${styles.inputUserIconEditProfile}`}
              />
              <input
                type="number"
                placeholder="Teléfono"
                name="cellphone"
                className={`form-control p-3 border-0 ${styles.inputTextHeader} ${styles.inputEditMaxWidth}`}
                value={this.state.user.cellphone}
                onChange={this.onChange}
                aria-describedby="basic-addon1"
                aria-label="telefono"
                disabled={(this.state.editPhone) ? "disabled": ""}
              />
              <span onClick={this.toggleDisabledInput} className={`${styles.hoverPointer} ${styles.borderEditInput}`} >
                <img
                  src={EditIcon}
                  alt="Edit icon"
                  name="editPhone"
                  className={`input-group-prepend ${styles.inputEditIconProfile}`}
                />
              </span>
            </div>

            <span 
              className={`${styles.optionText}  ${styles.hoverPointer}`} 
              onClick={()=>this.handleToggleModal("PASS")}
            >
              Cambiar contraseña
            </span>
          </ModalNew>
        )}

        {this.state.shownModal==="PASS" && (
          <ModalNew
            title="Cambiar Contraseña"
            show={this.state.shownModal==="PASS"}
            size="md"
            onHide={this.handleDisard}
            btnYesEvent={this.handleSubmit}
            btnYesName="Guardar"
            btnNoName="Cancelar"
          >
            <div className={`d-flex input-group ${styles.inputBlueBorder} mt-3 w-100 mx-auto bg-transparent`}>
              <img src={OpenLockIcon} alt="candado icon" className={`input-group-prepend ${styles.inputUserIconEditProfile}`}/>
              <input
                type="password"
                placeholder="Contraseña actual"
                name="password"
                className={`form-control p-3  border-0 ${styles.inputTextHeader}`}
                value={this.state.user.password}
                onChange={this.onChange}
                aria-describedby="basic-addon1"
                aria-label="contraseña actual"
              />
            </div>

            <div className={`d-flex input-group ${styles.inputBlueBorder} mt-3 w-100 mx-auto bg-transparent`}>
              <img src={OpenLockIcon} alt="candado icon" className={`input-group-prepend ${styles.inputUserIconEditProfile}`}/>
              <input
                type="password"
                placeholder="Nueva contraseña"
                name="newPassword"
                className={`form-control p-3  border-0 ${styles.inputTextHeader}`}
                value={this.state.user.newPassword}
                onChange={this.onChange}
                aria-describedby="basic-addon1"
                aria-label="nueva contraseña"
              />
            </div>

            <div className={`d-flex input-group mb-5 ${styles.inputBlueBorder} mt-3 w-100 mx-auto bg-transparent`}>
              <img src={OpenLockIcon} alt="candado icon" className={`input-group-prepend ${styles.inputUserIconEditProfile}`}/>
              <input
                type="password"
                placeholder="Confirmar contraseña"
                name="newPasswordConfirm"
                className={`form-control p-3  border-0 ${styles.inputTextHeader}`}
                value={this.state.user.newPasswordConfirm}
                onChange={this.onChange}
                aria-describedby="basic-addon1"
                aria-label="nueva contraseña"
              />
            </div>
          </ModalNew>
        )}

        {/* {this.state.showButtons && false && (
          
          <ModalNew
            title="Ayuda"
            show={this.state.showButtons}
            size="md"
            hideCancelButton={true}
            onHide={this.handleDisard}
            btnYesEvent={this.handleSubmit}
            btnYesName="Guardar"
            btnNoName="Cancelar"
          >
            <form 
              id="form-edit-profile" 
              className="d-flex w-100 justify-content-around position-absolute bottom-0 start-50 translate-middle" 
              onSubmit={this.handleSubmit}
            >
              <button className="btn btn-primary px-5" onClick={this.handleDisard}>Cancelar</button>
              <button className="btn btn-primary px-5" type="submit">Guardar</button>
            </form>
          </ModalNew>
        )} */}
        

        {this.state.shownModal==="HELP" && (
          <ModalNew
            title="Ayuda"
            show={this.state.shownModal==="HELP"}
            size="sm2"
            hideCancelButton={true}
            btnYesName="Aceptar"
            btnYesEvent={()=>this.handleToggleModal("")}
            onHide={()=>this.handleToggleModal("")}
          >
            <div 
              className={`d-flex flex-column`}
            >

              <img 
                className={styles.auydaLogo}
                src={logoBiowel} 
                alt="TTN" 
              />

              <p className={styles.ayudaSub}>Información de Contacto</p>

              <div className={`${styles.ayudaRow}`} >
                <img 
                  src={headerIconPhone} 
                  alt="headerIconPhone" 
                />
                <p>+57 300 658 32 65</p>
              </div>

              <div className={`${styles.ayudaRow}`} >
                <img 
                  src={headerIconMail} 
                  alt="headerIconMail" 
                />
                <p>ayuda@ttncompany.com</p>
              </div>

              <div className={`${styles.ayudaRow}`} >
                <img 
                  src={headerIconLocation} 
                  alt="headerIconLocation" 
                />
                <p>Calle 86 # 89-45</p>
              </div>

            </div>

          </ModalNew>
        )}

        <ModalNew
          title="Seleccionar Cuenta"
          show={this.state.shownModal==="ACCOUNT"}
          size="xl"
          hideCancelButton={true}
          btnYesName="Aceptar"
          btnYesEvent={()=>this.handleToggleModal("")}
          onHide={()=>this.handleToggleModal("")}
        >
        </ModalNew>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  isAuth: state.providerReducer.Authorization,
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
