import React from "react";

import {
    Route,
    withRouter,
    Redirect,
} from "react-router-dom";

import { connect } from 'react-redux'

const PrivateRouteProviderComponent = (props) => (
    <Route {...props.routeProps} render={() => (
        props.Authenticated ? (
                <div>{props.children}</div>
            ) 
            : 
            (
                <Redirect to={{
                    pathname: '/proveedores/login',
                    state: { from: props.location }
                }} />
            )
    )} />
);


const mapStateToProps = (state, ownProps) => {
    return {

        Authenticated: state.providerReducer.Authenticated,
        location: ownProps.path,
        routeProps: {
            exact: ownProps.exact,
            path: ownProps.path
        }
    };
};



const PrivateRoute = connect(mapStateToProps)(withRouter(PrivateRouteProviderComponent))

export default PrivateRoute 