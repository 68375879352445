import { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import PrivateRouteProviderComponent from "./PrivateRouteProvider";
import { Provider } from "react-redux";
import store from "../store";
import LoginProviders from "../components/ProvidersInterface/loginProviders";
import TableQuotations from "../components/ProvidersInterface/TableQuotations";
import FullLayout from "./FullLayout";
import Home from "../components/Home/Home";
import QuotationDetails from "../components/ProvidersInterface/QuotationDetails";
import VendorPurchaseOrder from "../components/TablePurchaseOrder/VendorPurchaseOrder";
import InvoiceProvide from "../components/TableBill/InvoiceProvide";
class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Router>
        <Provider store={store}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>

            <Route
              path="/login"
              component={LoginProviders}
              history={useHistory}
            />

            <Route exact path="/proveedores/login">
              <LoginProviders history={useHistory} />
            </Route>

            <PrivateRouteProviderComponent>
              <Route exact path="/proveedores/inicio">
                <FullLayout>
                  <Home />
                </FullLayout>
              </Route>
              <Route exact path="/proveedores/cotizaciones" render={(props) =>
                <FullLayout>
                  <TableQuotations {...props} />
                </FullLayout>
              }>
              </Route>
              <Route exact path="/proveedores/cotizaciones/:quotation" render={(props) =>
                <FullLayout>
                  <QuotationDetails {...props} />
                </FullLayout>
              }>
              </Route>
              <Route exact path="/proveedores/ordenDeCompra" render={(props) =>
                <FullLayout>
                  <VendorPurchaseOrder {...props} />
                </FullLayout>
              }>
              </Route>
              <Route exact path="/proveedores/facturas" render={(props) =>
                <FullLayout>
                  <InvoiceProvide {...props} />
                </FullLayout>
              }>
              </Route>
            </PrivateRouteProviderComponent>
          </Switch>
        </Provider>
      </Router>
    );
  }
}

export default Routes;