import React, { useEffect, useState } from "react";
import Lupa from "../../assets/img/icons/lupa.svg";
import Agregar from "../../assets/img/icons/agregarEspecialidad.svg";
import GenericTable from "../Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Col, Row } from "react-bootstrap";
import Engra from "../../assets/img/icons/engraneGris.svg";
import IndividualStyles from "../../components/Layouts/IndividualAuction_CCS.module.scss";
import { useHistory } from "react-router-dom";
import ModalNew from "../Layouts/ModalNew";
// import { edit_Invoice, getListInvoices } from "../../actions/InvoiceReceiptActions";
import { useDispatch, useSelector } from "react-redux";
import { convertDateToLatinFormat } from "../../helpers/convertDateFormat";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import Pagination from "react-js-pagination";
import StyleInvoice from "./InvoiceProvide.module.scss";
import CustomPopupExtend from "../Popup/customPopUpExtends";
// import CreateInvoice from "./CreateInvoice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { LOADING } from "../../actions/actionTypes";

function InvoiceProvide(props) {
    const MySwal = withReactContent(Swal);

    // funcion para formatear la fecha
    var date = new Date();
    const formatDate = (date) => {
        let formatted_date = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        return formatted_date;
    }

    // funcion para formatear valores de respuesta
    const numberWithCommas = (x) => {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(".");
    }

    const [contador, setContador] = useState(0);
    const [details, setDetails] = useState({ show: false, });
    const dispatch = useDispatch();
    const counter = useSelector(state => state);
    const [trigger, setTrigger] = useState(1);
    const [justifi, setJustifi] = useState([]);
    const [filters, setFilter] = useState({
        search: "",
        page: 1,
        perpage: 10,
        status: "",
        // entity_account: counter.loginReducer.currentAccount.id,
    });
    useEffect(() => {
        // dispatch(getListInvoices(filters));
    }, [contador, filters.page, trigger]);

    let history = useHistory();
    function handleClick() {
        history.push("/tesoreria/RecepcionDeFacturas/NuevaFactura");
    }
    // const myPermission = () => counter.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "listpinvoi");
    useEffect(() => {
        // if (!!!myPermission()?.read) {
        //     history.push("/tesoreria/inicio");
        // }
    })
    const handleDeleteRecord = (id, number) => {
        Swal.fire({
            title:
                `<h2 class="swal2-title" id="swal2-title" style="display: flex; color:#01a0f6 ; font-size:2rem">¿Está seguro?</h2>`,
            text: `Se anulará la factura ${number}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#003f80',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Continuar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                setShowModal(true)
            }
        })
        setJustifi({
            ...justifi,
            id: id,
            status: 6
        })
        // dispatch(edit_Invoice({ id: id, status: 6, No: number }, () => setTrigger(trigger + 1)))

    }
    const handleRegisterRecord = (id, number) => {
        Swal.fire({
            title:
                `<h2 class="swal2-title" id="swal2-title" style="display: flex; color:#01a0f6 ; font-size:2rem">¿Está seguro?</h2>`,
            text: `Se registrar la factura ${number}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#003f80',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Continuar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({
                    type: LOADING,
                    payload: true
                })
                // dispatch(edit_Invoice({ id: id, status: 2 }, () => setTrigger(trigger + 1)))
            }
        })


    }

    const resetSearch = () => {
        setFilter({
            ...filters,
            page: 1,
            perpage: 10,
        });
        // dispatch(getListInvoices(filters));
    }

    const handlePageChange = (val) => {
        setFilter({
            ...filters,
            page: val
        });
        // getListInvoices(filters)
    }

    const [showModal, setShowModal] = useState(false);

    const header = [

        <th className="col-1 text-center">Número</th>,
        <th className="text-start" style={{ paddingLeft: "1rem" }}>Proveedor</th>,
        <th className="col-1 text-center">Fecha</th>,
        <th className="col-1 text-center">Vence</th>,
        <th className="col-3 text-center">No. Entrada</th>,
        <th className="col-1 text-center">Valor total</th>,
        <th className="col-1 text-center">Estado</th>,
        <th className="col-1 text-center"></th>
    ]
    const body = []
    var color = "";
    var name1 = "";
    if (Array.isArray(counter?.InvoiceReceiptReducer?.results)) {
        counter?.InvoiceReceiptReducer?.results?.map((item) => {
            if (item.status == 1) {
                color = "#58595B"
                name1 = "Precargada"
            } else if (item.status == 2) {
                color = "#00A551"
                name1 = "Registrada"
            } else if (item.status == 3) {
                color = "#5942CD"
                name1 = "Causada"
            } else if (item.status == 4) {
                color = "#005DBF"
                name1 = "Parcialmente Pagada"
            } else if (item.status == 5) {
                color = "#E9B419"
                name1 = "Pagada"
            } else if (item.status == 6) {
                color = "#EC1C24"
                name1 = "Anulada"
            }

            body.push(
                <tr>
                    <td className="text-start" style={{ paddingLeft: "1rem" }}>{item.invoice_nbr}</td>
                    <td className="text-start" style={{ paddingLeft: "1rem" }}>{item.provider_name}</td>
                    <td className="text-center">{convertDateToLatinFormat(item.exp_date)}</td>
                    <td className="text-center">{convertDateToLatinFormat(item.due_date)}</td>
                    <td className="text-center">
                        {item.warehouse_entry_id == null ? "-" : item.warehouse_entry_id.map(x => {
                            if (item.warehouse_entry_id.length > 1) {
                                return x.id + " | "
                            }
                            else {
                                return x.id
                            }

                        })}

                    </td>
                    <td className="text-end" style={{ paddingRight: "1rem" }}>{"$" + numberWithCommas(item.total_amount)}</td>
                    <td className="text-center">
                        <div className="rounded-pill "
                            style={{ backgroundColor: `${color}20`, padding: "2px 10px" }}>
                            <b
                                style={{ verticalAlign: "middle", color: `${color}` }}>
                                {name1}
                            </b>
                        </div>
                    </td>
                    <td className="text-center">
                        {!!item.status ?
                            <CustomPopupExtend
                                showDelete={item.status == 1 ? true : false}
                                showDetails={item.status != 1 ? true : false}
                                showRegister={item.status == 1 ? true : false}
                                // showEdit={item.status === 2 || item.status === 4 || item.status === 5 ? true : false}
                                deleteText="Anular"
                                textDetails="Ver detalle"
                                // editClickEvent={() => setDetails({ ...details, show: true, edit: true, info: item })}
                                showDetailsEvent={() => setDetails({ ...details, show: true, details: true, edit: false, info: item })}
                                deleteEvent={() => handleDeleteRecord(item.id, item.invoice_nbr)}
                                registerEvent={() => handleRegisterRecord(item.id, item.invoice_nbr)}
                            />
                            : <img className="text-start w-15"
                                style={{ width: "20px" }}
                                src={Engra}
                            />}


                    </td>
                </tr>

            )
        })
    }
    const renderListPinvoices =

        <>
            <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>
                <div className="d-flex">
                    <h1 className={`${tableStyles.title} text-primary`}>Facturas</h1>
                </div>
                <Row className="d-flex">
                    <Col xs={2}>
                        <div className="display-grid mt-1">
                            <label className={tableStyles.crudModalLabel}>Estado</label>
                            <select
                                className={IndividualStyles.registerInputsGris}
                                onChange={(e) => setFilter({
                                    ...filters,
                                    status: e.target.value
                                })}
                            >
                                <option></option>
                                <option value="1">Precargada</option>
                                <option value="2">Registrada</option>
                                <option value="3">Causada</option>
                                <option value="4">Parcialmente Pagada</option>
                                <option value="5">Pagada</option>
                                <option value="6">Anulada</option>
                            </select>
                        </div>
                    </Col>
                    <Col xs={4}>
                        <label className={`${tableStyles.crudModalLabel}`} style={{ marginTop: "8px" }}
                        >ㅤ</label>
                        <input
                            className={StyleInvoice.inputSearch}
                            placeholder="Buscar..."
                            type="text"
                            onChange={(e) => setFilter({
                                ...filters,
                                search: e.target.value
                            })}
                        ></input>
                    </Col>
                    <Col xs={3}>
                        <i><img
                            className="cursorPointer"
                            style={{ marginTop: "30px" }}
                            src={Lupa}
                            onClick={() => resetSearch()}
                        /></i>
                    </Col>
                    {/* {!!myPermission()?.create ? */}
                        <Col xs={3} className="text-end " onClick={handleClick}>
                            <label className={`cursorPointer ${StyleInvoice.bgBtn}`} style={{ marginTop: "25px", padding: "5px" }}>Nueva Factura
                                <img
                                    className="ml-3 "
                                    style={{ width: "18px" }}
                                    src={Agregar} />
                            </label>

                        </Col> 
                        {/* : "" }*/}

                </Row>
                <div>
                    <ModalNew
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        title={"Anulación"}
                        // btnYesEvent={() => dispatch(edit_Invoice({ id: justifi.id, status: justifi.status, anullment_justif: justifi.anullment_justif }, () => { setTrigger(trigger + 1); setJustifi([]); setShowModal(false) }))}
                        btnYesName={"Continuar"}
                        size={"500"}
                        btnYesDisabled={!!justifi.anullment_justif ? false : true}

                    >
                        <Row className="d-flex">
                            <Col className="d-grid" xs={12}>
                                <label className={tableStyles.crudModalLabel}>Justificación</label>
                                <textarea className={IndividualStyles.textAreaClear}
                                    onChange={(e) => setJustifi({
                                        ...justifi,
                                        anullment_justif: e.target.value
                                    })}
                                />
                            </Col>
                        </Row>
                    </ModalNew>

                </div>
                <GenericTable
                    headers={header}
                    children={body}
                    dark={true}
                >
                </GenericTable>
                <div className={paginationStyles.wrapper}>
                    <p className={paginationStyles.paginationText}>
                        Pag. {counter?.InvoiceReceiptReducer?.total ? filters.page : ""}
                        {" de "}
                        {Math.ceil(counter?.InvoiceReceiptReducer?.total / filters.perpage) ?
                            Math.ceil(counter?.InvoiceReceiptReducer?.total / filters.perpage)
                            :
                            ""
                        }
                        {" "}
                        ({counter?.InvoiceReceiptReducer?.total} encontrados)
                    </p>
                    <Pagination
                        activePage={filters.page}
                        itemsCountPerPage={filters.perpage}
                        totalItemsCount={counter?.InvoiceReceiptReducer?.total}
                        pageRangeDisplayed={5}
                        onChange={(handlePageChange)}
                        itemClassPrev={`${paginationStyles.itemClassPrev}`}
                        itemClassNext={`${paginationStyles.itemClassNext} `}
                        itemClassFirst={`${paginationStyles.itemClassFirst} `}
                        itemClassLast={`${paginationStyles.itemClassLast} `}
                        itemClass={paginationStyles.itemClass}
                    />
                </div>
            </div>

        </>;
    return (!!details.show) ? ""
        // <CreateInvoice
        //     setDetails={setDetails}
        //     info={details.info}
        //     edit={details.edit}
        //     details={details.details}
        //     show={details.show}
        // /> 
        : renderListPinvoices

};
export default InvoiceProvide;
