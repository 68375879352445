import { GET_ACCOUNTS } from "../actions/actionTypes"

var initialState = {
    accounts: []
}
export const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ACCOUNTS:
            return {
                ...state,
                accounts: action.payload
            }
        default:
            return state
    }
}