import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Clock from "../../assets/img/icons/Reloj.svg";
import SearchIcon from "../../assets/img/icons/lupa.svg";
import paginationStyles from "../Layouts/pagination.module.scss";
import { connect } from "react-redux";
import "reactjs-popup/dist/index.css";
import Pagination from "react-js-pagination";
import {
  CURDATE,
  defaultFiltersQuotations,  
  PAGE,
  PERPAGE,  
} from "../../helpers/constants";
import style from "../TableProviders/providers.module.scss";
import ownStyles from "./providerInterface.module.scss";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import GenericTableNew from "../Layouts/GenericTableNew";
import { convertDateToLatinFormat } from "../../helpers/convertDateFormat";
import CustomPopupExtend from "../Popup/customPopUpExtends";
import tableStyles from "../Layouts/tableStyle.module.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import deepcopy from "deepcopy";
import { getAuctions, getAuctionsClients, getAuctionArticles } from "../../actions/quotationActions";
import { getAllAccounts } from "../../actions/accountActions";
import ModalNew from "../Layouts/ModalNew";
import { responseSwal } from "../../helpers/responseSwal";
const MySwal = withReactContent(Swal);

class TableQuotations extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {    
    const id_provider = this.props.provider?.length > 0 ? this.props.provider[0].id : null
    this.props.getAuctionsClients(id_provider, {current_date:CURDATE})
    this.props.getAuctions({...defaultFiltersQuotations, provider:id_provider})
    this.props.getAllAccounts()    
    this.setState(this.initialState);
  }

  componentDidUpdate(prevProps,prevState) {
    if (prevProps !== this.props) {
      if (prevProps.auctionArticles !== this.props.auctionArticles) {    
        this.setState({ ...this.state, addAuction: {
          ...this.state.addAuction,
          articles:this.props.auctionArticles
        }})
      }      
    }
    if (prevState !== this.state) {
      if (prevState.addAuction.articles !== this.state.addAuction.articles) {
        let {countChecked,selectedList} = this.state;
        countChecked=0;
        for (let i = 0; i < this.state.addAuction?.articles?.length; i++) {
          const element = this.state.addAuction.articles[i];
          if(element.checked) {
            if (!selectedList.includes(element.id)) selectedList.push(element.id);
            countChecked++
          }
        }
        // if (selectedList.length === this.state.addAuction.articles.length) checkModal = true;
        this.setState({countChecked,selectedList})
      }
      // valida si cambia el número de artículos seleccionados
      if (this.state.countChecked !== this.state.selectedList.length) {
        this.setState({...this.state, countChecked: this.state.selectedList.length})
      }
      
    }
  }
  
  componentWillUnmount() {
    this.setState(this.initialState);
  }

  get initialState() {
    return {
      modal: false,
      modalDeliveries:false,
      editRecord: false,
      addAuction: { 
        articles:[],
      },
      article: { },
      checkModal:false,
      countChecked:0,
      paginationModal:{
        page:1,
        perpage:10,
        totalIndex:0        
      },
      filters: {
        search: "",
        status:"",
        due_date:"",
        current_date:CURDATE,
        entityAccount:"",
        perpage: PERPAGE,
        page: PAGE,
        provider:this.props?.provider.length > 0 ? this.props.provider[0].id : null,
      },
      paginationDeliveries:{
        page:1,
        perpage:10
      },
      selectedList:[],
      headerState: [
        "ㅤ",
        "Vence",
        "Hora",
        "Condición de pago",
        "Nombre de la cotización",
        "Cliente",
        "Moneda",
        "Estado",
        "ㅤ",
      ],
      
    };
  }
  
  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.setState({ filters });
    this.props.getAuctions(this.state.filters);
  }
  // pagination articles 🗃 
  handlePageChangeModalArticle(pageNumber) {
    let { paginationModal } = this.state;
    paginationModal.page = pageNumber;
    this.setState({ paginationModal });
    this.props.getAuctionArticles(this.state.addAuction.id, {page:pageNumber, perpage:10});
  }
  // pagination deliveries 🚚 
  handlePageChangeModalDeliveries(pageNumber) {
    let { paginationDeliveries, addAuction } = this.state;
    paginationDeliveries.page = pageNumber;
    let min = pageNumber * paginationDeliveries.perpage - paginationDeliveries.perpage
    let max = pageNumber * paginationDeliveries.perpage
    addAuction.articles.slice(min,max)
    this.setState({ paginationDeliveries });
  }

  
  handleEditRecord = (auction) => {
    let { addAuction, modal,checkModal } = this.state;
    modal = !modal
    // checkModal = true;
    addAuction = deepcopy(auction);
    this.props.getAuctionArticles(auction.id,{page:1, perpage:10})
    this.setState({ addAuction, modal, checkModal });
  };

  // toggleStatus = (account) => {
  //   // this.props.changeStatus(account);
  // };

  // Search puc when user was typing
  onKeyUpSearch = (e) => {
    const value = e.target.value;
    let { filters } = this.state;
    filters.search = value;
    if (e.keyCode === 13) {
      filters.page = 1;
      this.setState({ filters });
      this.props.getAuctions(this.state.filters);
    } else {
      e.preventDefault();
    }
    this.setState({ filters });
  };

  //Method to search when click in magnify lens
  handleSearch = (e) => {
    let { filters } = this.state;
    filters.page = 1;
    this.setState({ filters });
    this.props.getAuctions(this.state.filters);
  };

  toggleModal = (e) => {
    // let { modal } = this.state;
    // modal = !modal;
    // if (this.state.editRecord) {
    //   this.setState({ modal });
    // }
    // this.setState(this.initialState);
    // this.setState({ modal });
  };

  toggleModalDataSheet = (e) => {
    // let { modalDataSheet } = this.state;
    // modalDataSheet = !modalDataSheet;
    // this.setState({ modalDataSheet });
  };

  onKeyDownNumber = (e) => {
    let { errorInputs } = this.state;
    if (e.keyCode === 69 || e.keyCode === 107 || e.keyCode === 109) {
      e.preventDefault();
      errorInputs = true;
      return MySwal.fire({
        icon: "error",
        title: "Este campo solo acepta números",
      });
    }
    errorInputs = false;
    this.setState({ errorInputs });
  };

  handleSubmit() {
    // this.toggleModal();
    // if (this.state.editRecord) {
    //   return this.props.updateArticle(this.state.addArticle);
    // }
    // // this.props.createArticle(this.state.addArticle);
    // this.setState(this.initialState);
  }  

  //   toggle visibilty of modal to edit or create record
  handleOnHide = () => {
    let { modal, addAuction, selectedList } = this.state;
    modal = !modal;
    addAuction = { articles : []}
    selectedList = []
    this.setState({ modal, addAuction, selectedList });
  };

  // Method to get css style for circle in status
  getStatusClass = (status) => {
    switch (status) {
      case "incomplete":
        return {class:tableStyles.circleYellowAuto, text:"Incompleto"}

      case "disabled":
        return {class:tableStyles.circleRedAuto, text:"Deshabilitado"}

      case "enabled":
        return {class:tableStyles.circleGreenAuto, text:"Habilitado"}

      case "Atendido":
        return {class:tableStyles.circleGreenAuto, text:"Atendida"}

      case "solved":
        return {class:tableStyles.circleGreenAuto, text:"Atendida"}

      case "partially":
        return {class:tableStyles.circleYellowAuto, text:"Parcialmente respondida"}

      case "pending":
        return {class:tableStyles.circleRedAuto, text:"Pendiente"}

      case "Solicitado":
        return {class:tableStyles.circleRedAuto, text:"Solicitado"}

      default:
        return {class:tableStyles.circleRedAuto, text:"No respondida"}
    }
  };  
  getClassCIIU = (id_ciiu) => {
    let klass = "-";
    this.props.ciiu.map((item) => {
      if (item.id === parseInt(id_ciiu)) klass = item.klass;
    });
    return klass;
  };

  getClientName = (id_account) => {
    let client = this.props.accounts?.filter(x => x.id === id_account)
    if (client?.length > 0) {
      return client[0].name
    }else{
      return "-"
    }
  }

  getCoinNickname = (coin) => {
    switch (coin) {
      case "dolar":
        return "USD"
      case "peso":
        return "COP"
      case "Dolar estadunidense":
        return "USD"
      case "Peso colombiano":
        return "COP"
      default:
        return "-"
    }
  }

  getPaymentCondition = (pay) => {
    switch (pay) {
      case "prepayment":
        return "Pago anticipado"
      case "delivery":
        return "Pago contraentrega"
      case "pay20":
        return "Pago a 20 días"
      case "pay30":
        return "Pago a 30 días"
      case "pay60":
        return "Pago a 60 días"
      case "pay90":
        return "Pago a 90 días"
      case null:
        return "-"
      default:
        return pay
    }
  }

  onChangeForm = (event) => {
    const {name, value} = event.target
    let {addAuction} = this.state;
    addAuction[name] = value;
    this.setState({addAuction})
  }
  onChangeCheckModal = (event, index) => {
    let {addAuction, countChecked, selectedList, checkModal} = this.state;
    countChecked=0;
    addAuction.articles[index].checked = !addAuction.articles[index].checked;    
    let element = addAuction.articles[index]
    if (addAuction.articles[index].checked === false){
      selectedList = selectedList.filter(x => x !== element.id)
    }else if (!selectedList.includes(element.id)) selectedList.push(element.id)

    for (let i = 0; i < addAuction.articles.length; i++) {
      const element = addAuction.articles[i];
      if(!!element.checked) {
        countChecked++
      }else{
        countChecked--
      }
    }
    selectedList.length === countChecked ? checkModal = true : checkModal = false
    this.setState({addAuction,countChecked,selectedList, checkModal})
  }

  // Method to render providers in table
  renderAuctions = (auction) => {
    // let created_at = auction.created_at
    //   ? convertDateToLatinFormat(auction.created_at)
    //   : "-";
    // let updated_at = auction.updated_at
    //   ? convertDateToLatinFormat(auction.updated_at)
    //   : "-";
    let client = this.getClientName(auction.account);
    let coin = this.getCoinNickname(auction.coin);
    let payment = this.getPaymentCondition(auction.pay_conditions);
    let circleClass = this.getStatusClass(auction.status);
    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        {circleClass.text}
      </Tooltip>
    );
    return (
      <tr key={`auct-${auction.id}`} className="hover-table-row align-middle">
        <td></td>
        <td className="text-center">{auction.date_expiry ? convertDateToLatinFormat(auction.date_expiry) : "-"}</td>
        <td className="text-center">{auction.hour_expiry ? auction.hour_expiry : "-"}</td>
        <td className="text-center">{payment}</td>
        <td className="text-left">{auction.description ? auction.description : "-"}</td>
        <td className="text-center">{client}</td>
        <td className="text-center">{coin}</td>
        {/* <td>{auction.status ? auction.status : "-"}</td> */}
        <td>
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <div className={circleClass.class}></div>
          </OverlayTrigger>
        </td>
        <td>
          <CustomPopupExtend
            showEdit={true}
            // showEnable = {true}
            editText={auction.status === "solved" ? "Ver cotización" : "Cotizar"}
            iconUpdate={true}
            showDetails={true}
            textDetails="Ver solicitud"
            showDetailsEvent={()=>this.handleEditRecord(auction)}
            // enableClickEvent={()=>this.toggleStatus}
            editClickEvent={
              () => {
                setTimeout(async() => {
                  await this.handleEditRecord(auction);                
                  await this.props.history.push(`/proveedores/cotizaciones/${auction.id}`, {selectedList:this.state.selectedList, auction:this.state.addAuction});
                }, 10);
              }
            }
            // showDelete={true}
            // deleteEvent={() => this.handleDeleteRecord}
          />
        </td>
      </tr>
    );
  };

  renderArticles = (article, index) => {
    if (this.state.selectedList.includes(article.id)) article.checked = true;
    if (this.state.paginationModal.page>1) index += 10;
    return (
      <tr key={article.id}>
        <td className="text-center w-10">
          <input type="checkbox"
            className="form-check-input border-clear-blue p05 bg-clear-blue"
            name="checkArticle"
            onChange={(e)=>this.onChangeCheckModal(e,index)}
            checked={article.checked} />
        </td>
        <td className="text-center">{article.description ? article.description : "-" }</td>
        <td className="text-center">{article.id ? article.id : "-" }</td>
        <td className="text-center">{article.qty_article ? article.qty_article : "-" }</td>
        <td className="text-center">
          <img src={Clock} alt="entregas" width="18" className={article?.deliveries.length > 0 ? "" : `${ownStyles.clock} disabled` } onClick={()=>this.setState({...this.state, modalDeliveries:true, modal:false, article:article})}/>
        </td>
      </tr>
    )
  }
  renderDeliveres = (article, index) => {
    let realIndex = index+1
    let count= `${this.state.paginationDeliveries.page-1 > 0 ? this.state.paginationDeliveries.page-1 : ""}${realIndex}`    
    if(realIndex> 1 &&realIndex % 10 ===0) count = this.state.paginationDeliveries.page*10
    return (
      <tr key={article.id}>
        <td className="text-center w-10">
          {count}
        </td>
        <td className="text-center">{article.delivery_date ? article.delivery_date : "-" }</td>
        <td className="text-center">{article.quantity ? article.quantity : "-" }</td>
      </tr>
    )
  }
  onChangeFilter = (event) => {
    const {name, value} = event.target;
    let { filters } = this.state;
    filters[name] = value;
    this.setState({filters})
    this.props.getAuctions(filters)
  }

  handleCheckAll = () => {
    let {checkModal, countChecked, addAuction, selectedList} = this.state;
    checkModal = !this.state.checkModal
    for (let i = 0; i < addAuction.articles.length; i++) {
      let element = addAuction.articles[i];
      element.checked = checkModal;
      if (checkModal === true){        
        if (!selectedList.includes(element.id)) selectedList.push(element.id);
      }else{        
        selectedList = [];
      }
      countChecked = this.props.totalArticles
    }
    this.setState({checkModal, countChecked, addAuction, selectedList})
  }

  nextDetails = () => {
    let {addAuction, selectedList} = this.state;
    addAuction.articles = addAuction.articles.filter(x => selectedList.includes(x.id))
    if (addAuction.status === "solved") {
      addAuction.pay_conditions = ""
    }
    this.setState({addAuction, selectedList})
    this.props.history.push(`/proveedores/cotizaciones/${this.state.addAuction.id}`, {auction:this.state.addAuction})
  }

  // ANCHOR RENDER METHOD
  render() {
    if (!this.props.isAuth) {
      return <Redirect to={"/proveedores/login"}/>
    }
    
    let renderHeaders = this.state.headerState
      ? this.state.headerState.map((header) => {
          return <th className="text-center">{header}</th>;
        })
      : "";
    var renderAuctions = this.props.listAuctions?.map((provider) => {
          return this.renderAuctions(provider);
        })
    let min = (this.state.paginationModal.page * this.state.paginationModal.perpage) - this.state.paginationModal.perpage
    let max = (this.state.paginationModal.page * this.state.paginationModal.perpage)
    var renderArticlesModal = this.state.addAuction?.articles?.slice(min,max).map((article, index) => { 
          return this.renderArticles(article, index);
        })

    var renderDeliveries = this.state.article.deliveries
      ? this.state.article.deliveries.slice(
        (this.state.paginationDeliveries.page*this.state.paginationDeliveries.perpage)-this.state.paginationDeliveries.perpage,
        this.state.paginationDeliveries.page*this.state.paginationDeliveries.perpage).map((article, index) => {
          return this.renderDeliveres(article, index);
        })
      : "";

    
    var renderHeadersModal =  [
      <input type="checkbox" className="form-check-input border-clear-blue p05"
        checked={this.state.checkModal}
        onChange={this.handleCheckAll}
        />,
      "Descripción del producto",
      "Código",
      "Cantidad",
      "Entrega Parcial"
    ].map((header) => {
          return <th className="text-center">{header}</th>;
        })

    var renderHeadersDeliveries = ["#","Fecha", "Cantidad"].map((header, index) => {
        if(index === 0) return <th className="text-center border-end w-15">{header}</th>;
        else{return <th className="text-center">{header}</th>;}
        })
    
    var clientName = ""
    if (this.state.addAuction.client) {
      clientName = this.props?.clients?.map(client => {
        if (client.id === this.state.addAuction.client) {
          return client.name
        }
      })  
    }
        
    return (
      <div className="w-100 mt-3">
        {this.props.loading ? (
          <div className="loading">
            <Loader type="Oval" color="#003f80" height={100} width={100} />
          </div>
        ) : null}
        <div className={`w-100 ${this.props.loading ? "disabled" : ""}`}>        
          <div className="px-5 pb-5 mx-5">
            <div className="row">
              <h1 className="top-h1 text-primary">Cotizaciones</h1>
            </div>
            <div className="row">
              <div className="col-md-16 align-middle ">
                <div className="row">
                  <div className="col-md-12">
                    <div className={`${style.selectGroup}`} style={{maxWidth:"12rem"}}>
                      <label
                        htmlFor="due_date"
                        className={`${style.labelFont}`}
                      >
                        Fecha de vencimiento
                      </label>
                      <input
                        type="date"
                        name="due_date"                        
                        className={`${style.selectInput} w-100 text-primary fw-bold`}
                        onChange={this.onChangeFilter}
                      />                        
                    </div>
                    <div className={`${style.selectGroup}`}>
                      <label htmlFor="active" className={`${style.labelFont}`}>
                        Estado
                      </label>
                      <select
                        name="status"
                        id="status_id"
                        className={`${style.selectInput} w-100`}
                        onChange={this.onChangeFilter}
                      >
                        <option value=""></option>
                        <option value="solved">Atendida</option>
                        <option value="requested">Solicitada</option>
                      </select>
                    </div>
                    {/* <div className={`${style.selectGroup}`}>
                      <label htmlFor="active" className={`${style.labelFont}`}>
                        Cliente
                      </label>
                      <select
                        name="client"
                        id="client_id"
                        className={`${style.selectInput} w-100`}
                        onChange={this.onChangeFilter}
                      >
                        <option value=""></option>                        
                        {this.props.clients?.map(item=>{
                          return <option key={item.id} value={item.id}>{item.name}</option>
                        })}                        
                      </select>
                    </div> */}
                    <input
                      className={`${style.inputsearch} px-2`}
                      type="text"
                      name="search"
                      placeholder="Buscar..."
                      onKeyUp={this.onKeyUpSearch}
                    />
                    <img
                      src={SearchIcon}
                      alt="buscar"
                      className="icons-svg-top-table float-left hoverPointer"
                      onClick={this.handleSearch}
                    />
                  </div>
                </div>
              </div>
            </div>

            <GenericTableNew
              headers={renderHeaders}
              dark={true}
              lowerCase={true}
            >
              {renderAuctions}
            </GenericTableNew>
            <div
              className={`${paginationStyles.wrapper} justify-content-between`}
            >
              <p style={{ display: "inline" }}>
                Pag. {this.state.filters.page} de{" "}
                {Math.ceil(this.props.totalIndex / this.state.filters.perpage)
                  ? Math.ceil(
                      this.props.totalIndex / this.state.filters.perpage
                    )
                  : ""}{" "}
                ({this.props.totalIndex} encontrados)
              </p>
              <Pagination
                activePage={this.state.filters.page}
                itemsCountPerPage={10}
                totalItemsCount={this.props.totalIndex ? this.props.totalIndex : 10}
                pageRangeDisplayed={10}
                activeClass={paginationStyles.activeClass}
                onChange={this.handlePageChange.bind(this)}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          {/* <div className="d-flex juistify-content-start">
            <button className="btn" style={{backgroundColor:"#CECECE", color:"#fff", padding:"0.5rem 1.5rem"}}>Crear cotización</button>
          </div> */}
          </div>
        </div>
        <ModalNew
          onHide={this.handleOnHide}
          show={this.state.modal}
          size="xl"
          centered
          title={this.state.addAuction?.description}
          btnYesEvent={this.state.addAuction.status === "solved" ? false : () => this.nextDetails()}
          btnYesName={this.state.addAuction.status === "solved" ?  null : "Cotizar"}
          subtitle={this.state.addAuction.auction_number}
          // noPaddingBody
        >
          <div className="row justify-content-between w-100">
            <div className="d-flex flex-column col">
              <label htmlFor="due_date" className={style.labelFont}>Fecha de vencimiento</label>
              <input type="date" name="due_date"  className={`text-primary fw-bold ${style.selectInputClear}`} readOnly value={this.state.addAuction?.date_expiry} />
            </div>
            <div className="d-flex flex-column col-2">
              <label htmlFor="qty_articles" className={style.labelFont}>No. de artículos</label>
              <input type="number" min="0"  className={`text-primary fw-bold ${style.selectInputClear}`} value={this.props?.totalArticles} readOnly/>
            </div>
            <div className="d-flex flex-column col">
              <label htmlFor="client" className={style.labelFont}>Cliente</label>
              <input type="text" name="client" className={`text-primary ${style.selectInputClear}`} value={clientName} readOnly/>
            </div>
            <div className="d-flex flex-column col">
              <label htmlFor="pay_conditions" className={style.labelFont}>Términos y condiciones</label>
              <input type="text" name="pay_conditions" className={`${style.selectInputClear} bg-disabled-input text-secondary text-darkGray`} readOnly value={this.getPaymentCondition(this.state.addAuction?.pay_conditions)}/>
            </div>
          </div>
          <GenericTableNew
            headers={renderHeadersModal}>
              {renderArticlesModal}
          </GenericTableNew>
              <div className="d-flex justify-content-end">
              <Pagination
                    activePage={this.state.paginationModal.page}
                    itemsCountPerPage={this.state.paginationModal.perpage}
                    totalItemsCount={this.props?.totalArticles ? this.props.totalArticles : 10}
                    pageRangeDisplayed={10}
                    activeClass={paginationStyles.activeClass}
                    onChange={this.handlePageChangeModalArticle.bind(this)}
                    itemClassPrev={paginationStyles.itemClassPrev}
                    itemClassNext={paginationStyles.itemClassNext}
                    itemClassFirst={paginationStyles.itemClassFirst}
                    itemClassLast={paginationStyles.itemClassLast}
                    itemClass={paginationStyles.itemClass}
                  />
                </div>
                <div className="d-flex w-100 justify-content-between">
                  <div className="flex-column col-3">
                    <label htmlFor="selectedArticles" className={style.labelFont}>Artículos seleccionados</label>
                    <input type="text" name="selectedArticles" value={this.state.countChecked} readOnly className={`${style.inputText} w-100`}/>
                  </div>
                  <div className="flex-column col-9 paddingLeft1rem">
                    <label htmlFor="observations" className={style.labelFont}>Observaciones</label>
                    <input type="text" name="observations" value={this.state.addAuction?.observations} readOnly className={`${style.inputText} ml-2 w-100 text-darkGray`}/>
                  </div>
                </div>
        </ModalNew>
        {/* MODAL ENTREGAS PARCIALES */}
        <ModalNew
          title="Entregas Parciales"
          show={this.state.modalDeliveries}
          onHide={()=>{this.setState({...this.state, modalDeliveries:false, modal:true})}}
          leftArrowEvent={()=>{this.setState({...this.state, modalDeliveries:false, modal:true})}}
          >
        <div>
         <div className={ownStyles.containerHeadDeliveries}>
           <div className="d-flex row p-2 px-3">           
            <div className="d-flex flex-column col">
              <label htmlFor="description">Producto</label>
              <input type="text" className={style.inputText} readOnly value={this.state.article?.description}/>
            </div>
            <div className="d-flex flex-column col-3">
            <label htmlFor="description">Código</label>
              <input type="text" className={style.inputText} readOnly value={this.state.article?.id}/>
            </div>
            </div>
           <div className="d-flex row p-2 px-3">           
            <div className="d-flex flex-column col">
              <label htmlFor="description">Dirección de entrega</label>
              <input type="text" className={style.inputText} readOnly value={this.state.article?.address}/>
            </div>
            <div className="d-flex flex-column col-3">
            <label htmlFor="description">Cantidad</label>
              <input type="text" className={style.inputText} readOnly value={this.state.article?.qty_article}/>
            </div>
            </div>
          </div> 
          <h6 className="text-primary fw-bold mt-3">Programación solicitada</h6>
          <GenericTableNew headers={renderHeadersDeliveries}>
            {renderDeliveries}
          </GenericTableNew>
          <div className="d-flex w-100 justify-content-end">
            <Pagination
                activePage={this.state.paginationDeliveries.page}
                itemsCountPerPage={this.state.paginationDeliveries.perpage}
                totalItemsCount={this.state?.article?.deliveries?.length ? this.state.article.deliveries.length : 10}
                pageRangeDisplayed={10}
                activeClass={paginationStyles.activeClass}
                onChange={this.handlePageChangeModalDeliveries.bind(this)}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
              </div>
          </div>
        </ModalNew>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isAuth: state.providerReducer.Authenticated,
  provider: state.providerReducer.providerSession,
  listAuctions: state.auctionReducer.listAuctions,
  accounts: state.accountReducer.accounts,
  totalIndex: state.auctionReducer.totalIndex,
  clients: state.auctionReducer.clients,
  auctionArticles: state.auctionReducer.auctionArticles,
  totalArticles: state.auctionReducer.totalArticles,
  loading: state.auctionReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getAuctions: (filters) => {
    dispatch(getAuctions(filters))
  },
  getAllAccounts: () => {
    dispatch(getAllAccounts())
  },
  getAuctionsClients: (id_provider, params) => {
    dispatch(getAuctionsClients(id_provider, params))
  },
  getAuctionArticles: (id_quotation, params) => {
    dispatch(getAuctionArticles(id_quotation, params))
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(TableQuotations);
