import { Component } from "react";
import { Link } from "react-router-dom";
import logoBiowel from "../../assets/img/icons/bioWel.svg";
import styles from "../../components/Header/headerStyle.module.scss";

class HeaderProviders extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  // componentDidMount() {
  // }

// componentDidUpdate(prevProps) {
  
// }

  handleToggleAccount = () => {
    this.setState({
      showAccountSelect: !this.state.showAccountSelect,
    });
  };
    
  render() {
    return (
      <div className={`${styles.headerContainer} w-100`}>
        <Link to="/proveedores/inicio">
          <img
            className="my-3 mx-3"
            src={logoBiowel}
            alt="logo biowel header"
            width="160"
          />
        </Link>                                
      </div>
    );
  }
}
// const mapStateToProps = (state, ownProps) => ({

// });

// const mapDispatchToProps = (dispatch) => ({
  
// });

// export default connect(mapStateToProps, mapDispatchToProps)(HeaderProviders);
export default HeaderProviders;
