import React, { Component } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import "./sidebar.scss";
import style from "../../routes/fullLayout.module.css";

import engraneAzul from "../../assets/img/icons/engraneAzul.svg";
import Autorizacion from "../../assets/img/icons/Autorizacion.svg";
import Compra from "../../assets/img/icons/Compra.svg";
import logOut from "../../assets/img/icons/logOut.svg";
import CentrodeCosto from "../../assets/img/icons/CentrodeCosto.svg";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsedMenu: true,
      itemActive: "",
    };
  }

  onClickToggleMenu = () => {
    this.setState({ collapsedMenu: !this.state.collapsedMenu });
  };

  onClickToggleSubMenu = () => {
    if (this.state.collapsedMenu) {
      this.onClickToggleMenu();
    } else {
      let { itemActive } = this.state;
      if (itemActive !== "") {
        this.setState({ itemActive: "" });
      } else {
        this.setState({ itemActive: "pro-inner-item-active" });
      }
    }
  };

  render() {
    return (
      <div
        className={`${style.fullLayoutSideBar} shadow rounded d-inline-block full-height`}
        onMouseEnter={() =>
          this.setState({ ...this.state, collapsedMenu: false })
        }
        onMouseLeave={() =>
          this.setState({ ...this.state, collapsedMenu: true })
        }
      >
        <ProSidebar collapsed={this.state.collapsedMenu}>
          <Menu id="menu1" iconShape="square">
            <MenuItem
              icon={<img src={Autorizacion} alt="cotizaciones" />}
              className={`activeHover`}
            >
              <Link to={`/proveedores/cotizaciones`}>
                <label style={{ "padding-left": "5px" }}></label>
                <label>Cotizaciones</label>
              </Link>
            </MenuItem>
            <MenuItem
              icon={<img src={Compra} alt="cotizaciones" />}
              className={`activeHover`}
            >
              <Link to="/proveedores/ordenDeCompra">
                <label style={{ "padding-left": "5px" }}></label>
                <label>ordenes de compra</label>
              </Link>
            </MenuItem>
            <MenuItem
              icon={<img src={CentrodeCosto} alt="facturas" />}
              className={`activeHover`}
            >
              <Link to="/proveedor/:id/facturas">
                <label style={{ "padding-left": "5px" }}></label>
                <label>Facturas</label>
              </Link>
            </MenuItem>
            <MenuItem
              icon={<img src={engraneAzul} alt="configuration" />}
              className={`activeHover`}
            >
              <Link to="/proveedor/:id/configuracion">
                <label style={{ "padding-left": "5px" }}></label>
                <label>Configuración</label>
              </Link>
            </MenuItem>
          </Menu>
          <Menu>
            <MenuItem
              icon={<img src={logOut} alt="log out" />}
              className={`activeHover hoverPointer`}
            >
              <span onClick={() => this.props.logOut()}>
                <label style={{ paddingLeft: "5px" }}></label>
                <label className="hoverPointer">Cerrar Sesión</label>
              </span>
            </MenuItem>
          </Menu>
        </ProSidebar>
      </div>
    );
  }
}

export default withRouter(Sidebar);
