import { Component } from "react";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import { connect } from "react-redux";
import styles from "./fullLayout.module.css";
import { logOut } from "../actions/providersActions";
import HeaderProviders from "../components/ProvidersInterface/headerProviders";
class FullLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error:""
        }
    }
    render() {
        return (
            <div className={styles.fullLayout}>
                <HeaderProviders/>
                <div className={styles.fullLayoutBody}>
                    <Sidebar logOut={()=>this.props.logOut()} provider={this.props.provider}/>
                    <div className={styles.fullLayoutWorkArea} onClick={this.onClickHideAccountInfo}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    isAuth: state.providerReducer.Authenticated,
    provider: state.providerReducer.providerSession
  });
  
  const mapDispatchToProps = (dispatch) => ({
    logOut:()=>{
        dispatch(logOut())
    }
  });
  export default connect(mapStateToProps, mapDispatchToProps)(FullLayout);