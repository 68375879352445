export const CITY_GET = "CITY_GET";
export const PROVINCE_GET = "PROVINCE_GET";
export const COUNTRY_GET = "COUNTRY_GET";
export const SUCCESS_UPLOAD = "SUCCESS_UPLOAD";
export const LOADING = "LOADING";
export const GET_PROVIDERS = "GET_PROVIDERS";
export const PROVINCE_GET_ALTERNATIVE = "PROVINCE_GET_ALTERNATIVE";
export const CITY_GET_ALTERNATIVE = "CITY_GET_ALTERNATIVE";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_CIIU = "GET_CIIU";
export const GET_PROVIDER_CLASSIFICATION = "GET_PROVIDER_CLASSIFICATION";
export const CREATE_DRAFT_PROVIDER = "CREATE_DRAFT_PROVIDER";
export const PROVINCE_GET_ALL = "PROVINCE_GET_ALL";
export const GET_EDIT_PROVIDER = "GET_EDIT_PROVIDER";
export const CITY_GET_ALL = "CITY_GET_ALL";
export const GET_BANKING_ENTITIES = "GET_BANKING_ENTITIES";
export const LOGIN_PROVIDER_SUCCESS = "LOGIN_PROVIDER_SUCCESS";
export const PROVIDER_AUTH = "PROVIDER_AUTH";
export const GET_AUCTIONS = "GET_AUCTIONS";
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const GET_AUCTIONS_CLIENTS = "GET_AUCTIONS_CLIENTS";
export const GET_ARTICLES_AUCTION = "GET_ARTICLES_AUCTION";
export const GET_PURCHASEORDERSTATUS = "GET_PURCHASEORDERSTATUS";
export const GET_LIST_INVOICES = "GET_LIST_INVOICES";
export const GET_PURCHASE_ORDER = "GET_PURCHASE_ORDER";
