import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { responseSwal } from "../helpers/responseSwal";
import { GET_ACCOUNTS } from "./actionTypes";


export const getAllAccounts = () => (dispatch, getState) => {
    const token = getState().providerReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/admin/accounts/`,{
        method:"GET",
        headers:{
            accept:"application/json",
            Authorization: token,
            "Content-type":"application/json",
            "Set-Cookie-Origin":"switch=prov"
        }
    })
    .then((response) => response.json())
    .then(res=> {
        dispatch({
            type:GET_ACCOUNTS,
            payload:res.results
        })
    })
    .catch(()=>{
        return responseSwal({
            icon:"error",
            title:"Error",
            text:"Ocurrió un error al traer la información de los clientes"
        })
    })
}