import React, { Component } from "react";
import { connect } from "react-redux";
// import { logOutUser } from "../../actions/loginActions";
// import {getTotalIndex} from "../../actions/userActions";
import  "../../index.scss";
// import { verifyAccessToken } from "../../actions/loginActions";
import { defaultFilters } from "../../helpers/constants";
// import { getSites } from "../../actions/sitesActions";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onChange = (e) => {
    // let name = e.target.name;
    // const value = e.target.value;
  };

  componentDidMount(){
  }

  // componentDidUpdate(prevProps, prevState) {    
  // }  
  render() {
    return (
        <div className="d-flex w-100 mx-auto">
          <div className="container">
            <h1 className="title-blue mt-5 fw-bold">Bienvenido</h1>
           </div>
           <div className="homeBackground"></div>
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isAuth: state.providerReducer.Authenticated,
  // Authentication : state.providerReducer.Authorization
});

const mapDispatchToProps = (dispatch) => ({    
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
