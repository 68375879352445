import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { responseSwal } from "../helpers/responseSwal";
import { convertFilterToString } from "../helpers/convertToFilter";
import { message } from "../helpers/helpers";
// export const getPurchaseOrders = async(token, params) =>{
//     try{
//         const filters = convertFilterToString(params);
//         const query = fetch(`${URL_GATEWAY}${API_VERSION}/inventory/purchaseOrder?${filters}`,{
//             method: 'GET',
//             headers:{
//                 "Content-Type": "application/json",
//                 accept: 'application/json',
//                 Authorization: token
//             }
//         })
//         const response = await query.json()
//         return response
//     } catch(error){
//         console.error(error)
//          responseSwal({
//             icon:"error",
//             title:"Error",
//             text:"Ocurrió un error al traer la información de ordenes de compra"
//         })
//         return {'success':false}
//     }
// }

export const getPurchaseOrders = async (objFilters, token) => {
  const filters = convertFilterToString(objFilters);
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/inventory/provider/purchaseOrders/?${filters}`,
      {
        method: "GET",
        headers: {
          "Set-Cookie-Origin": "switch=prov",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    let result = await query.json();
    return result;
  } catch (error) {
    console.error(error.message);
    return message("error", "Error", "Ha ocurrido un error");
  }
};
