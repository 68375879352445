import { GET_ARTICLES_AUCTION, GET_AUCTIONS, GET_AUCTIONS_CLIENTS, LOADING } from "../actions/actionTypes"

var initialState = {
    listAuctions: [],
    totalIndex:0,
    clients:[],
    auctionArticles:[],
    totalArticles:0,
    loading:false
}
export const auctionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_AUCTIONS:
            return {
                ...state,
                listAuctions:action.payload,
                totalIndex:action.total_index
            }
        case GET_AUCTIONS_CLIENTS:
            return{
                ...state,
                clients:action.payload
            }
        case GET_ARTICLES_AUCTION:
            return{
                ...state,
                auctionArticles:action.payload,
                totalArticles:action.totalArticles
            }
        case LOADING:
            return {
                ...state,
                loading:action.payload
            }
        default:
            return state
    }
}