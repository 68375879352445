import React from "react";

//import "../TableUsers/tableUserStyle.scss";
import tableStyles from "./genericTable.module.scss";

function GenericTableNew (props){

    return (
      <table className={`table ${props.lowerCase ? tableStyles.genericTableLower : tableStyles.genericTable}`}>
        <thead className={`${tableStyles.thead} ${props.dark? tableStyles.theadDark : tableStyles.theadLight}`}>
          <tr>{props.headers}</tr>
        </thead>
          <tbody>        
            {props.children}        
          </tbody>
          {!!props.footer && (
            <tfoot>
              {props.footer}
            </tfoot>
          )}
      </table>
    );
}

export default GenericTableNew;
