import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { responseSwal } from "../helpers/responseSwal";
import { convertFilterToString } from "../helpers/convertToFilter";
import {
  GET_AUCTIONS,
  GET_AUCTIONS_CLIENTS,
  GET_ARTICLES_AUCTION,
  LOADING,
} from "./actionTypes";

export const getAuctions = (objFilters) => (dispatch, getState) => {
  dispatch({type:LOADING, payload:true})
  const filters = convertFilterToString(objFilters);
  const token = getState().providerReducer.Authorization;
  fetch(
    `${URL_GATEWAY}${API_VERSION}/inventory/provider/quotation?${filters}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
        "Set-Cookie-Origin": "switch=prov",
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      dispatch({type:LOADING, payload:false})
      dispatch({
        type: GET_AUCTIONS,
        payload: res.results,
        total_index: res.total_index,
      });
    })
    .catch(() => {
      dispatch({type:LOADING, payload:false})
      responseSwal({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error al traer la información de las cotizaciones",
      });
    });
};

export const getAuctionsClients =
  (id_provider, params) => (dispatch, getState) => {
    const token = getState().providerReducer.Authorization;
    const filters = convertFilterToString(params);

    fetch(
      `${URL_GATEWAY}${API_VERSION}/composite/provider/quotation/${id_provider}/clients?${filters}`,
      {
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
          "Set-Cookie-Origin": "switch=prov",
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: GET_AUCTIONS_CLIENTS,
          payload: res.results,
        });
      }).catch((error)=>{
        console.error(error);
        responseSwal({icon:"error", title:"Error", text:"Ocurrió un error al traer el listado de cotizaciones"})
      })
  };

export const getAuctionArticles =
  (id_quotation, params) => (dispatch, getState) => {
    const token = getState().providerReducer.Authorization;
    delete params["page"];
    delete params["perpage"];
    const filters = convertFilterToString(params);
    fetch(
      `${URL_GATEWAY}${API_VERSION}/inventory/provider/quotation/${id_quotation}/articles?${filters}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Set-Cookie-Origin": "switch=prov",
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          dispatch({
            type: GET_ARTICLES_AUCTION,
            payload: res.results,
            totalArticles: res.total_index,
          });
        } else {
          responseSwal({
            icon: "error",
            title: "Error",
            text: "Ocurrió un error al traer la información de los artículos",
          });
        }
      })
      .catch(() => {
        responseSwal({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error al traer la información de los artículos",
        });
      });
  };

export const getClientById = (id_client) => (dispatch, getState) => {
  const token = getState().providerReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/accounts/${id_client}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
      "Set-Cookie-Origin": "switch=prov",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      return res.name;
    });
};

export const sendQuotation = (objQuotation) => (dispatch, getState) => {
  const token = getState().providerReducer.Authorization;
  
  if (!objQuotation.pay_conditions) {
    return responseSwal({
      icon: "error",
      title: "Error",
      text: "Debe seleccionar la condición de pago",
    });
  }
  if (!objQuotation.quote_validity) {
    return responseSwal({
      icon: "error",
      title: "Error",
      text: "Debe seleccionar ingresar la fecha de validez",
    });
  }
  if (!objQuotation.signature_stamp) {
    return responseSwal({
      icon: "error",
      title: "Error",
      text: "Debe seleccionar ingresar la firma del proveedor",
    });
  }
  let verifyArticles = true;
  objQuotation.articles.map((item) => {
    if (!item.unit_price) {
      verifyArticles = false;
    }
  });
  if (!verifyArticles) {
    return responseSwal({
      icon: "error",
      title: "Error",
      text: "Los artículos a cotizar deben tener valor por unidad",
    });
  }
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/provider/quotation`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
      "Set-Cookie-Origin": "switch=prov",
    },
    body: JSON.stringify(objQuotation),
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        return responseSwal({
          icon: "success",
          title: `${res.message}`,
          html: `${res.results}`+'<br/>'+`${res.text}`,
          confirmButtonText:'<a href="/proveedores/cotizaciones" class="text-outline-clear-blue">Aceptar</a>'
        });
      } else {
        return responseSwal({
          icon: "error",
          title: `Error`,
          text: `Ocurrió un error al enviar la cotización`,
        });
      }
    })
    .catch(() => {
      responseSwal({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error al enviar la cotización",
      });
    });
};
