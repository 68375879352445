import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import question from "../../assets/img/icons/question.svg";
import Trash from "../../assets/img/icons/canecaAzul.svg";
import BackIcon from "../../assets/img/icons/atras-icon.svg";
import Discount from "../../assets/img/icons/discount.svg";
import paginationStyles from "../Layouts/pagination.module.scss";
import { connect } from "react-redux";
import "reactjs-popup/dist/index.css";
import Pagination from "react-js-pagination";
import {
  ACCEPT_IMAGES,
  CURDATE,  
  PAGE,
  PERPAGE,  
} from "../../helpers/constants";
import deepcopy from "deepcopy";
import style from "../TableProviders/providers.module.scss";
import ownStyles from "./providerInterface.module.scss";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import agregarEspecialidad from "../../assets/img/icons/agregarEspecialidad.svg";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import GenericTableNew from "../Layouts/GenericTableNew";
import Clock from "../../assets/img/icons/Reloj.svg";
// import { convertDateToLatinFormat } from "../../helpers/convertDateFormat";
// import CustomPopupExtend from "../Popup/customPopUpExtends";
import tableStyles from "../Layouts/tableStyle.module.scss";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  getAuctions,
  getAuctionsClients,
  getAuctionArticles,
  sendQuotation,
} from "../../actions/quotationActions";
import { getAllAccounts } from "../../actions/accountActions";
import ModalNew from "../Layouts/ModalNew";
import NumberFormat from "react-number-format";
import Dropzone from "react-dropzone";
import { responseSwal } from "../../helpers/responseSwal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { GET_AUCTIONS } from "../../actions/actionTypes";
const MySwal = withReactContent(Swal);

class QuotationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    const id_quotation = this.props.match.params.quotation;
    const id_provider = this.props?.provider[0].id;
    this.props.getAuctions({ id: id_quotation });
    if (this.props.location?.state?.auction?.status === "solved")
      this.props.getAuctionArticles(id_quotation, { solved: 1 });
    this.props.getAuctionsClients(id_provider, {});    
    this.setState(this.initialState);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.addAuction.status === "solved" &&
      this.props.auctionArticles
    ) {
      if (this.state.addAuction.articles !== this.props.auctionArticles) {
        this.setState({
          ...this.state,
          addAuction: {
            ...this.state.addAuction,
            articles: this.props.auctionArticles,
          },
        });
      }
    }
    if (prevProps !== this.props) {
      if (prevProps.auctionArticles !== this.props.auctionArticles) {
        this.setState({
          ...this.state,
          addAuction: {
            ...this.state.addAuction,
            articles: this.props.auctionArticles,
          },
        });
      }
    }
    if (prevState !== this.state) {
      if (prevState.addAuction?.articles?.length !== this.state.addAuction?.articles?.length) {
        let { countChecked, selectedListArticles } = this.state;
        countChecked = 0;
        if (this.state.addAuction.articles?.length > 0) {
          for (let i = 0; i < this.state.addAuction.articles.length; i++) {
            const element = this.state.addAuction.articles[i];
            if (element.checked) {
              if (!selectedListArticles.includes(element.id)) selectedListArticles.push(element.id);
              countChecked++;
            }else if (selectedListArticles.includes(element.id)) {
              selectedListArticles = selectedListArticles.filter(x => x !== element.id)              
            }
          }
          this.setState({ countChecked, selectedListArticles });
        }
      }
      // valida si cambia el número de artículos seleccionados
      if (this.state.countChecked !== this.state.selectedListArticles.length) {
        this.setState({
          ...this.state,
          countChecked: this.state.selectedListArticles.length,
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.cleanAuctionList();
    this.setState(this.initialState);
  }

  get initialState() {
    let addAuction = null;
    if (this.props?.location?.state?.auction) {
      addAuction = this.props.location.state.auction;
      if (addAuction?.articles?.length > 0) {
        addAuction.articles.forEach((x) => {
          x.checked = false;
        });
      }
      if (addAuction.status !== "solved") {
        addAuction.pay_conditions = ""
      }
    }
    return {
      modal: false,
      redirect: false,
      modalDeliveries: false,
      editRecord: false,
      addAuction: addAuction,
      article: {},
      checkTable: false,
      collapsedDiscount: true,
      countChecked: 0,
      fileValid: false,
      fileUpload: "",
      paginationModal: {
        page: 1,
        perpage: 10,
        totalIndex: 0,
      },
      filters: {
        search: "",
        status: "",
        quote_validity: "",
        current_date: CURDATE,
        entityAccount: "",
        perpage: PERPAGE,
        page: PAGE,
        provider: this.props.match.params.id,
      },
      paginationDeliveries: {
        page: 1,
        perpage: 10,
      },
      selectedListArticles: [],
      prevSelectedListArticles: [],
    };
  }

  onKeyDownNumber = (e) => {
    let { errorInputs } = this.state;
    if (e.keyCode === 69 || e.keyCode === 107 || e.keyCode === 109) {
      e.preventDefault();
      errorInputs = true;
      return MySwal.fire({
        icon: "error",
        title: "Este campo solo acepta números",
      });
    }
    errorInputs = false;
    this.setState({ errorInputs });
  };


  handlePageChangeModalArticle(pageNumber) {
    let { paginationModal } = this.state;
    paginationModal.page = pageNumber;
    this.setState({ paginationModal });
    this.props.getAuctionArticles(this.state.addAuction.id, {page:pageNumber, perpage:10});
  }


  handleSubmit = (event) => {
    event.preventDefault();
    let data = deepcopy(this.state.addAuction);
    data.id = this.props.match.params.quotation;
    data.signature_stamp = this.props.provider[0].signature;
    responseSwal({
      icon:"warning",
      title:"¿Está seguro?",
      text:"Verifique la información",
      confirmButtonText:"Si, enviar",
      cancelButtonText:"Cancelar",
      showCancelButton:true
    }).then((result)=> {
      if (result.isConfirmed) {
        this.props.sendQuotation(data, () => this.setState({...this.initialState, redirect:true}));        
      }
    })
  };

  handleDeleteRecord = (article) => {
    // let { addArticle } = this.state;
    // addArticle = deepcopy(article);
    // this.toggleModalDataSheet();
    // this.setState({ addArticle });
  };

  //   toggle visibilty of modal to edit or create record
  handleOnHide = () => {
    let { modal, addAuction, selectedListArticles, prevSelectedListArticles } = this.state;
    modal = !modal;
    selectedListArticles = deepcopy(prevSelectedListArticles)
    addAuction.articles = addAuction.articles.filter(x => !!selectedListArticles.includes(x.id))
    this.setState({ modal, addAuction, selectedListArticles});
  };

  // Method to get css style for circle in status
  getStatusClass = (status) => {
    switch (status) {
      case "incomplete":
        return { class: tableStyles.circleYellowAuto, text: "Incompleto" };

      case "disabled":
        return { class: tableStyles.circleRedAuto, text: "Deshabilitado" };

      case "enabled":
        return { class: tableStyles.circleGreenAuto, text: "Habilitado" };

      case "Atendido":
        return { class: tableStyles.circleGreenAuto, text: "Atendida" };

      case "solved":
        return { class: tableStyles.circleGreenAuto, text: "Atendida" };

      case "partially":
        return {
          class: tableStyles.circleYellowAuto,
          text: "Parcialmente respondida",
        };

      case "pending":
        return { class: tableStyles.circleRedAuto, text: "Pendiente" };

      case "Solicitado":
        return { class: tableStyles.circleRedAuto, text: "Solicitado" };

      default:
        return { class: tableStyles.circleRedAuto, text: "No respondida" };
    }
  };
  getClassCIIU = (id_ciiu) => {
    let klass = "-";
    this.props.ciiu.map((item) => {
      if (item.id === parseInt(id_ciiu)) klass = item.klass;
    });
    return klass;
  };

  getClientName = (id_account) => {
    let client = this.props.accounts?.filter((x) => x.id === id_account);
    if (client?.length > 0) {
      return client[0].name;
    } else {
      return "-";
    }
  };

  getCoinNickname = (coin) => {
    switch (coin) {
      case "dolar":
        return "USD";
      case "peso":
        return "COP";
      case "Dolar estadunidense":
        return "USD";
      case "Peso colombiano":
        return "COP";
      default:
        return "-";
    }
  };

  getPaymentCondition = (pay) => {
    switch (pay) {
      case "prepayment":
        return "Pago anticipado";
      case "delivery":
        return "Pago contraentrega";
      case "pay20":
        return "Pago a 20 días";
      case "pay30":
        return "Pago a 30 días";
      case "pay60":
        return "Pago a 60 días";
      case "pay90":
        return "Pago a 90 días";
      case null:
        return "-";
      default:
        return pay;
    }
  };

  onBlurInputDate = (event) => {
    const {value, name} = event.target
    let {addAuction} = this.state;
    if (value < CURDATE) {      
      addAuction[name] = ""
      responseSwal({
        icon:"error",
        title:"Error",
        text:"Fecha inválida"
      })
      this.setState({addAuction})
    }
  }

  onChangeForm = (event) => {
    const { name, value } = event.target;
    let { addAuction } = this.state;    
    addAuction[name] = value;
    this.setState({ addAuction });
  };
  onChangeCheckTable = (event, index) => {
    let { addAuction, countChecked, selectedListArticles, checkTable } =
      this.state;
    countChecked = 0;
    if (addAuction.articles[index]) {
      let element = addAuction.articles[index];
      element.checked = !element.checked;
      if (element.checked === false) {
        selectedListArticles = selectedListArticles.filter((x) => x !== element.id);
      } else if (!selectedListArticles.includes(element.id))
        selectedListArticles.push(element.id);
      let verify = addAuction.articles.filter((x) => !x.checked);
      verify.length > 0 ? (checkTable = false) : (checkTable = true);
      this.setState({
        addAuction,
        countChecked,
        selectedListArticles,
        checkTable,
      });
    }
  };
  onChangeInputOnTable = (event, index) => {
    let { addAuction, countChecked, selectedListArticles, checkTable } =
      this.state;
    const { name, value } = event.target;
    countChecked = 0;
    addAuction.articles[index][name] = value;
    let element = addAuction.articles[index];
    if (addAuction.articles[index].checked === false) {
      selectedListArticles = selectedListArticles.filter(
        (x) => x !== element.id
      );
    } else if (!selectedListArticles.includes(element.id))
      selectedListArticles.push(element.id);

    for (let i = 0; i < addAuction.articles.length; i++) {
      const element = addAuction.articles[i];
      if (!!element.checked) {
        countChecked++;
      } else {
        checkTable = false;
      }
    }
    this.setState({
      addAuction,
      countChecked,
      selectedListArticles,
      checkTable,
    });
  };

  renderArticles = (article, index) => {
    if (this.state.selectedListArticles.includes(article.id))
      article.checked = true;
    article.iva_value =
      article?.iva && article.unit_price && article.qty_article
        ? (article.iva / 100) * (article.unit_price * article.qty_article)
        : 0;
    if (article.qty_article && article.unit_price) {
      article.subtotal = article.qty_article * article.unit_price;
    }
    return (
      <tr key={article.id}>
        <td className="text-center w-10">
          <input
            type="checkbox"
            name="checkArticle"
            className="border border-dark-blue form-check-input p05 check-dark-blue"
            onChange={(e) => this.onChangeCheckTable(e, index)}
            checked={article.checked}
          />
        </td>
        <td className="text-left">
          {article.description ? article.description : "-"}
        </td>
        <td className="text-center">
          <input
            type="text"
            name="brand"
            className={ownStyles.inputTextTable}
            value={article.brand ? article.brand : ""}
            placeholder="-"
            onChange={(e) => this.onChangeInputOnTable(e, index)}
            disabled={this.state.addAuction.status === "solved" ? true : false}
          />
        </td>
        <td className="text-center">
          <input
            type="text"
            name="health_register"
            className={ownStyles.inputTextTable}
            value={article.health_register ? article.health_register : ""}
            placeholder="-"
            onChange={(e) => this.onChangeInputOnTable(e, index)}
            disabled={this.state.addAuction.status === "solved" ? true : false}
          />
        </td>
        <td className="text-center">
          <NumberFormat
            name="qty_article"
            disabled={this.state.addAuction.status === "solved" ? true : false}
            allowNegative={false}
            className={ownStyles.inputTextTable}
            value={article.qty_article ? article.qty_article : ""}
            placeholder="-"
            maxLength={14}
            onValueChange={(values) => {
              let { addAuction } = this.state;
              let value = values.value;
              if (parseInt(value) === 0) value = 1;
              addAuction.articles[index].qty_article = value;
              this.setState({ addAuction });
            }}
          />
        </td>
        <td className="text-center">
          <NumberFormat
            name="unit_price"
            thousandSeparator
            maxLength={14}
            allowNegative={false}
            disabled={this.state.addAuction.status === "solved" ? true : false}
            prefix={article.unit_price ? "$" : ""}
            className={ownStyles.inputTextTable}
            value={article.unit_price ? article.unit_price : ""}
            placeholder="-"
            onValueChange={(values) => {
              let { addAuction } = this.state;
              addAuction.articles[index].unit_price = values.value;
              if (article.qty_article)
                addAuction.articles[index].subtotal =
                  values.value * article.qty_article;
              if (article.discount && addAuction?.articles[index].qty_article){                
                  addAuction.articles[index].discount_value =
                  (article.discount / 100) * ((values.value * addAuction.articles[index].qty_article));
                }
              this.setState({ addAuction });
            }}
          />
        </td>
        <td className="text-center">
          <div className="d-flex justify-content-around align-items-center">
            <NumberFormat
              name="discount"
              allowNegative={false}
              suffix={
                this.state.addAuction.discount || article.discount ? "%" : ""
              }
              className={ownStyles.inputTextTable}
              value={article.discount ? article.discount : ""}
              placeholder="-"
              readOnly
            />
            <NumberFormat
              name="discount_value"
              thousandSeparator
              allowNegative={false}
              prefix={article.discount && article.unit_price ? "$" : ""}
              className={ownStyles.inputTextTable}
              value={
                article.discount && article.unit_price && article.qty_article
                  ? (article.discount / 100) * ((article.unit_price * article.qty_article))
                  : ""
              }
              readOnly
              placeholder="-"
            />
          </div>
        </td>
        <td className="text-center">
          <div className="d-flex justify-content-around">
            <NumberFormat
              name="iva"
              thousandSeparator
              allowNegative={false}
              suffix="%"
              value={article.iva ? article.iva : 0}
              className={ownStyles.inputTextTable}
              readOnly
              isAllowed={false}
            />
            <NumberFormat
              name="iva_value"
              thousandSeparator
              allowNegative={false}
              prefix="$"
              value={article.iva_value}
              className={ownStyles.inputTextTable}
              readOnly
              isAllowed={false}
            />
          </div>
        </td>
        <td className="text-center">
          {article.subtotal
            ? `$${article.subtotal.toLocaleString().slice(0, 14)}`
            : "-"}
        </td>
      </tr>
    );
  };
  renderDeliveres = (article, index) => {
    let realIndex = index + 1;
    let count = `${
      this.state.paginationDeliveries.page - 1 > 0
        ? this.state.paginationDeliveries.page - 1
        : ""
    }${realIndex}`;
    if (realIndex > 1 && realIndex % 10 === 0)
      count = this.state.paginationDeliveries.page * 10;
    return (
      <tr key={article.id}>
        <td className="text-center w-10">{count}</td>
        <td className="text-center">
          {article.delivery_date ? article.delivery_date : "-"}
        </td>
        <td className="text-center">
          {article.quantity ? article.quantity : "-"}
        </td>
      </tr>
    );
  };
  onChangeFilter = (event) => {
    const { name, value } = event.target;
    let { filters } = this.state;
    filters[name] = value;
    this.setState({ filters });
    this.props.getAuctions(filters);
  };

  handleCheckAll = () => {
    let { checkTable, countChecked, addAuction, selectedListArticles } =
      this.state;
    checkTable = !this.state.checkTable;
    for (let i = 0; i < addAuction.articles?.length; i++) {
      let element = addAuction.articles[i];
      element.checked = checkTable;
      if (checkTable === true) {
      if (!selectedListArticles.includes(element.id))
          selectedListArticles.push(element.id);
      }else{
        selectedListArticles = []
      }
      countChecked = this.props.totalArticles;
    }
    this.setState({ checkTable, countChecked, addAuction, selectedListArticles });
  };

  // convert file to base64 ☄
  // convertBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file);

  //     fileReader.onload = () => {
  //       resolve(fileReader.result);
  //     };

  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  // onchange signature to validate a file
  // validateFileSignature = (acceptedFiles) => {
  //   let {fileValid, fileUpload, addAuction} = this.state
  //   fileUpload = acceptedFiles
  //   let file = acceptedFiles[0];
  //   if (!!file){
  //   if (file.size > MAX_SIZE_FILE) {
  //     fileValid = false
  //     responseSwal({
  //       icon:'error',
  //       title:"Error",
  //       text:"El archivo es demasiado pesado",
  //       footer:"El tamaño máximo permitido es de 5MB"
  //     })
  //   }else if (new RegExp(REGEX_IMAGES).test(file.name)) {
  //     fileValid = true;
  //   }else{
  //     fileValid = false
  //     responseSwal({
  //       icon:'error',
  //       title:"Error",
  //       text:"El formato del archivo no es válido",
  //       footer:"Debe ser una imagen y el tamaño máximo permitido es de 5MB"
  //     })
  //   }
  //   if (fileValid) {
  //     setTimeout(async() => {
  //       let encode = await this.convertBase64(file);
  //       addAuction.signature_stamp = encode
  //       addAuction.filenamesignature_stamp = file.name
  //       this.setState({addAuction})
  //     }, 10);
  //   }
  // }else if (!file){
  //   addAuction.signature_stamp = ""
  //   this.setState({addAuction})
  // }
  //   this.setState({fileValid, fileUpload, addAuction})
  // }

  deleteArticles = () => {
    if (this.state.addAuction.status !== "solved") {
      let { addAuction, selectedListArticles } = this.state;
      if (addAuction.articles && selectedListArticles.length > 0) {
        addAuction.articles = addAuction.articles.filter(
          (x) => !selectedListArticles.includes(x.id)
        );
        if (addAuction.articles.length === 0) {
          return responseSwal({
            icon: "error",
            title: "Error",
            text: "Debe seleccionar al menos un artículo para enviar una cotización",
          });
        } else {
          this.setState({ addAuction });
        }
      }
    }
  };

  renderTooltip = (props) => (
    <Tooltip placement="top-end" id="top-tooltip" {...props}>
      Valores sin descuento y sin IVA
    </Tooltip>
  );

  renderTableFooter = (tfoot) => {
    return tfoot;
  };

  // pagination deliveries 🚚 
  handlePageChangeModalDeliveries(pageNumber) {
    let { paginationDeliveries, addAuction } = this.state;
    paginationDeliveries.page = pageNumber;
    let min = pageNumber * paginationDeliveries.perpage - paginationDeliveries.perpage
    let max = pageNumber * paginationDeliveries.perpage
    addAuction.articles.slice(min,max)
    this.setState({ paginationDeliveries });
  }

  handleLoadArticles = (quotation) => {    
    let {selectedListArticles, prevSelectedListArticles} = this.state;
    this.props.getAuctionArticles(quotation.id,{page:1, perpage:10})
    selectedListArticles = [];
    quotation?.articles?.forEach(article=>{
      if (!selectedListArticles.includes(article.id)) selectedListArticles.push(article.id);
    })
    prevSelectedListArticles = deepcopy(selectedListArticles)
    this.setState({...this.state, modal:true, selectedListArticles, prevSelectedListArticles});
  }

  onChangeCheckModal = (event, index) => {
    let {addAuction, countChecked, selectedListArticles, checkModal} = this.state;
    countChecked=0;
    addAuction.articles[index].checked = !addAuction.articles[index].checked;    
    let element = addAuction.articles[index]
    if (addAuction.articles[index].checked === false){
      selectedListArticles = selectedListArticles.filter(x => x !== element.id)
    }else if (!selectedListArticles.includes(element.id)) selectedListArticles.push(element.id)

    for (let i = 0; i < addAuction.articles.length; i++) {
      const element = addAuction.articles[i];
      if(!!element.checked) {
        countChecked++
      }else{
        checkModal = false
      }

    }
    this.setState({addAuction,countChecked,selectedListArticles, checkModal})
  }


  renderArticlesModal = (article, index) => {
    if (this.state?.selectedListArticles.includes(article.id)) article.checked = true;
    
    if (this.state.paginationModal.page>1) index += 10;
    return (
      <tr key={article.id}>
        <td className="text-center w-10">
          <input type="checkbox"
            className="form-check-input border-dark-blue p05"
            name="checkArticle"
            onChange={(e)=>this.onChangeCheckModal(e,index)}
            checked={article.checked} />
        </td>
        <td className="text-center">{article.description ? article.description : "-" }</td>
        <td className="text-center">{article.id ? article.id : "-" }</td>
        <td className="text-center">{article.qty_article ? article.qty_article : "-" }</td>
        <td className="text-center">
          <img src={Clock} alt="entregas" width="18" className={article?.deliveries?.length > 0 ? "" : ownStyles.clock } onClick={()=>this.setState({...this.state, modalDeliveries:true, modal:false, article:article})}/>
        </td>
      </tr>
    )
  }


  nextDetails = () => {
    let {addAuction, selectedListArticles} = this.state;
    addAuction.articles = addAuction.articles.filter(x => selectedListArticles.includes(x.id))
    if (addAuction.status === "solved") {
      addAuction.pay_conditions = ""
    }
    this.setState({addAuction, selectedListArticles, modal:false})  
  }

  // ANCHOR RENDER METHOD
  render() {   
    if (!this.props.isAuth) {
      return <Redirect to={"/proveedores/login"} />;
    }
    if (!!this.state.redirect) {
      return <Redirect to={"/proveedores/cotizaciones"} />;
    }

    const headerState = [
      // "",
      <input
        type="checkbox"
        className="form-check-input mx-auto text-center border-dark-blue p-2"
        onChange={this.handleCheckAll}
        checked={this.state.checkTable}
      />,
      "Descripción del artículo",
      "Marca",
      "Registro sanitario",
      "Cant.  ",
      "Valor unidad",
      <div className="mx-auto text-center px-3">
        Descuento
        <br />
        <div className="d-flex justify-content-around">
          <span>%</span>
          <span>$</span>
        </div>
      </div>,
      <div className="mx-auto text-center">
        IVA
        <br />
        <div className="d-flex justify-content-around">
          <span>%</span>
          <span>$</span>
        </div>
      </div>,
      <div className="px-3">SubTotal</div>,
      // "",
    ];

    let renderHeaders = headerState.map((header) => {
      if (header === "Registro sanitario") return <th className="text-center w-15">{header}</th>;
      return <th className="text-center">{header}</th>;
    });

    var renderArticles = this.state.addAuction?.articles?.map(
      (article, index) => {
        if (this.state.modal) {
          if (!!article.checked) return this.renderArticles(article, index);            
        }else{
          return this.renderArticles(article, index);
        }
      }
    );

    var clientName = "";
    if (!!this.state.addAuction?.client) {
      clientName = this.props?.clients?.map((client) => {
        if (client.id === this.state.addAuction.client) {
          return client.name;
        }
      });
    }

    let completeSubtotal = 0,
      completeDiscount = 0,
      completeIvaValue = 0;
    if (this.state.addAuction?.articles?.length > 0) {
      this.state.addAuction.articles.forEach((item) => {
        if (item.subtotal) completeSubtotal += item.subtotal;
        if (item.discount_value) completeDiscount += item.discount_value;
        if (item.iva_value) completeIvaValue += item.iva_value;
      });
    }

    let disabledSend = false;
    if (!this.state.addAuction.articles) disabledSend = true
    this.state.addAuction?.articles?.forEach((item) => {
      if (!item.qty_article || !item.unit_price) disabledSend = true;
    });
    if (this.state.addAuction.status === "solved") disabledSend = true;
    if (this.state.addAuction?.articles?.length === 0) disabledSend = true;

    let renderFooter = [
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td className="p-2">
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={this.renderTooltip}
          >
            <img
              style={{ "vertical-align": "top" }}
              src={question}
              width="12"
            />
          </OverlayTrigger>
          <span className="p-1">Subtotal</span>
        </td>
        <td className="flex-wrap text-center">
          $
          {this.state.addAuction.status === "solved"
            ? this.state.addAuction.subtotal.toLocaleString().slice(0, 14)
            : completeSubtotal.toLocaleString().slice(0, 14)}
        </td>
      </tr>,
      <tr>
        <td className="invisible"></td>
        <td className="invisible"></td>
        <td className="invisible"></td>
        <td className="invisible"></td>
        <td className="invisible"></td>
        <td className="invisible"></td>
        <td className="invisible"></td>
        <td className="px-4">Descuento</td>
        <td className="text-center">
          $
          {this.state.addAuction.status === "solved"
            ? this.state.addAuction.total_discount.toLocaleString().slice(0, 14)
            : completeDiscount.toLocaleString().slice(0, 14)}
        </td>
      </tr>,
      <tr>
        <td className="invisible"></td>
        <td className="invisible"></td>
        <td className="invisible"></td>
        <td className="invisible"></td>
        <td className="invisible"></td>
        <td className="invisible"></td>
        <td className="invisible"></td>
        <td className="px-4">IVA</td>
        <td className="text-center">
          $
          {this.state.addAuction.status === "solved"
            ? this.state.addAuction.total_iva.toLocaleString().slice(0, 14)
            : completeIvaValue.toLocaleString().slice(0, 14)}
        </td>
      </tr>,
      <tr>
        <td className="invisible"></td>
        <td className="invisible"></td>
        <td className="invisible"></td>
        <td className="invisible"></td>
        <td className="invisible"></td>
        <td className="invisible"></td>
        <td className="invisible"></td>
        <td className="text-primary fw-bold fs-6 px-4">TOTAL</td>
        <td className="text-primary fw-bold fs-6 text-center">
          $
          {this.state.addAuction.status === "solved"
            ? this.state.addAuction.total.toLocaleString().slice(0, 14)
            : (completeSubtotal + completeIvaValue - completeDiscount)
                .toLocaleString()
                .slice(0, 14)}
        </td>
      </tr>,
    ].map((item) => {
      return this.renderTableFooter(item);
    });


    var renderHeadersModal =  [
      <input type="checkbox" className="form-check-input border-dark-blue p05"
        checked={this.state.checkModal}
        onChange={this.handleCheckAll}
        />,
      "Descripción del producto",
      "Código",
      "Cantidad",
      "Entrega Parcial"
    ].map((header) => {
          return <th className="text-center">{header}</th>;
        })

    let min = (this.state.paginationModal.page * this.state.paginationModal.perpage) - this.state.paginationModal.perpage
    let max = (this.state.paginationModal.page * this.state.paginationModal.perpage)
    var renderArticlesModal = this.state.addAuction?.articles?.slice(min,max).map((article, index) => { 
          return this.renderArticlesModal(article, index);
        })
      
    var renderDeliveries = this.state.article.deliveries
      ? this.state.article.deliveries.slice(
        (this.state.paginationDeliveries.page*this.state.paginationDeliveries.perpage)-this.state.paginationDeliveries.perpage,
        this.state.paginationDeliveries.page*this.state.paginationDeliveries.perpage).map((article, index) => {
          return this.renderDeliveres(article, index);
        })
      : "";

    var renderHeadersDeliveries = ["#","Fecha", "Cantidad"].map((header, index) => {
        if(index === 0) return <th className="text-center border-end w-15">{header}</th>;
        else{return <th className="text-center">{header}</th>;}
        })
    

    // Articles modal
    const articlesModal = (
      <ModalNew
          onHide={this.handleOnHide}
          show={this.state.modal}
          size="xl"
          centered
          title={this.state.addAuction?.description}
          btnYesName={this.state.addAuction.status === "solved" ?  null : "Cotizar"}
          btnYesEvent={this.state.addAuction.status === "solved" ? false : () => this.nextDetails()}
          subtitle={this.state.addAuction.auction_number}          
        >
          <div className="row justify-content-between  w-100">
            <div className="d-flex flex-column col">
              <label htmlFor="due_date" className={style.labelFont}>Fecha de vencimiento</label>
              <input type="date" name="due_date"  className={style.selectInput} readOnly value={this.state.addAuction?.date_expiry} />
            </div>
            <div className="d-flex flex-column col-2">
              <label htmlFor="qty_articles" className={style.labelFont}>No. de artículos</label>
              <input type="number" min="0"  className={style.selectInput} value={this.props?.totalArticles} readOnly/>
            </div>
            <div className="d-flex flex-column col">
              <label htmlFor="client" className={style.labelFont}>Cliente</label>
              <input type="text" name="client" className={style.selectInput} value={clientName} readOnly/>
            </div>
            <div className="d-flex flex-column col">
              <label htmlFor="pay_conditions" className={style.labelFont}>Términos y condiciones</label>
              <input type="text" name="pay_conditions" className={`${style.selectInput} bg-disabled-input text-darkGray`} readOnly value={this.getPaymentCondition(this.state.addAuction?.pay_conditions)}/>
            </div>
          </div>
          <GenericTableNew
            headers={renderHeadersModal}>
              {renderArticlesModal}
          </GenericTableNew>
              <div className="d-flex justify-content-end">
              <Pagination
                    activePage={this.state.paginationModal.page}
                    itemsCountPerPage={this.state.paginationModal.perpage}
                    totalItemsCount={this.props?.totalArticles ? this.props.totalArticles : 10}
                    pageRangeDisplayed={10}
                    activeClass={paginationStyles.activeClass}
                    onChange={this.handlePageChangeModalArticle.bind(this)}
                    itemClassPrev={paginationStyles.itemClassPrev}
                    itemClassNext={paginationStyles.itemClassNext}
                    itemClassFirst={paginationStyles.itemClassFirst}
                    itemClassLast={paginationStyles.itemClassLast}
                    itemClass={paginationStyles.itemClass}
                  />
                </div>
                <div className="d-flex w-100 justify-content-between">
                  <div className="flex-column col-3">
                    <label htmlFor="selectedArticles" className={style.labelFont}>Artículos seleccionados</label>
                    <input type="text" name="selectedArticles" value={this.state.countChecked} readOnly className={`${style.inputText} w-100`}/>
                  </div>
                  <div className="flex-column col-9 paddingLeft1rem">
                    <label htmlFor="observations" className={style.labelFont}>Observaciones</label>
                    <input type="text" name="observations" value={this.state.addAuction?.observations} readOnly className={`${style.inputText} ml-2 w-100 text-darkGray`}/>
                  </div>
                </div>
        </ModalNew>
    )

    const modalDeliveres = (      
        <ModalNew
          title="Entregas Parciales"
          show={this.state.modalDeliveries}
          onHide={()=>{this.setState({...this.state, modalDeliveries:false, modal:true})}}
          leftArrowEvent={()=>{this.setState({...this.state, modalDeliveries:false, modal:true})}}
          >
        <div>
         <div className={ownStyles.containerHeadDeliveries}>
           <div className="d-flex row p-2 px-3">           
            <div className="d-flex flex-column col">
              <label htmlFor="description">Producto</label>
              <input type="text" className={style.inputText} readOnly value={this.state.article?.description}/>
            </div>
            <div className="d-flex flex-column col-3">
            <label htmlFor="description">Código</label>
              <input type="text" className={style.inputText} readOnly value={this.state.article?.id}/>
            </div>
            </div>
           <div className="d-flex row p-2 px-3">           
            <div className="d-flex flex-column col">
              <label htmlFor="description">Dirección de entrega</label>
              <input type="text" className={style.inputText} readOnly value={this.state.article?.address}/>
            </div>
            <div className="d-flex flex-column col-3">
            <label htmlFor="description">Cantidad</label>
              <input type="text" className={style.inputText} readOnly value={this.state.article?.qty_article}/>
            </div>
            </div>
          </div> 
          <h6 className="text-primary fw-bold mt-3">Programación solicitada</h6>
          <GenericTableNew headers={renderHeadersDeliveries}>
            {renderDeliveries}
          </GenericTableNew>
          <div className="d-flex w-100 justify-content-end">
            <Pagination
                activePage={this.state.paginationDeliveries.page}
                itemsCountPerPage={this.state.paginationDeliveries.perpage}
                totalItemsCount={this.state?.article?.deliveries?.length ? this.state.article.deliveries.length : 10}
                pageRangeDisplayed={10}
                activeClass={paginationStyles.activeClass}
                onChange={this.handlePageChangeModalDeliveries.bind(this)}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
              </div>
          </div>
        </ModalNew>
    )

    return (
      <div className="w-100 mt-3">
        {articlesModal}
        {modalDeliveres}
        {this.props.loading ? (
          <div className="loading">
            <Loader type="Oval" color="#003f80" height={100} width={100} />
          </div>
        ) : null}
        <div className={`mx-5 ${this.props.loading ? "disabled" : ""}`}>
          <div className="container-fluid pb-5 mx-auto px-5">
            <div className="row">
              <div className="d-flex">
                <Link to="/proveedores/cotizaciones">
                  <img src={BackIcon} alt="volver" width={18} className="mt-2 mr-2"/>
                </Link>
                <h1 className="top-h1 text-primary mb-0">
                  {this.props?.listAuctions?.length > 0
                    ? this.props.listAuctions[0].description
                    : ""}
                </h1>
              </div>
              <div className="d-flex justify-content-between" style={{margin:"0 1.8rem"}}>
                <h5 className="text-primary mt-0">{clientName}</h5>
                {this.state.addAuction.status !== "solved" && (
                  <div
                    className={style.groupBounding} 
                    onClick={()=>this.handleLoadArticles(this.state.addAuction)}
                    style={{marginRight:"3.5rem"}}                  
                  >
                    <label htmlFor="addArticle" className="mx-1 text-darkGray fw-bold mt-1">Agregar artículo</label>
                    <img src={agregarEspecialidad} alt="agregar articulo" name="addArticle" width={18}/>
                  </div>
                )}
              </div>
            </div>
            <div className="row mx-3">
              <div className="d-flex align-middle ">
                <div className="row justify-content-between">
                  <div className="d-flex">
                    <div className={`${style.selectGroup} col-3`}>
                      <label
                        htmlFor="create_at"
                        className={`${style.labelFont}`}
                      >
                        Fecha de creación
                      </label>
                      <input
                        type="date"
                        name="create_at"
                        className={`${style.selectInput} w-100 text-primary fw-bold ${ownStyles.inputDisabled}`}
                        value={CURDATE}
                        readOnly
                        disabled={
                          this.state.addAuction.status === "solved"
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className={`${style.selectGroup} col-3`}>
                      <label
                        htmlFor="quote_validity"
                        className={`${style.labelFont}`}
                      >
                        Validez de la cotización*
                      </label>
                      <input
                        type="date"
                        name="quote_validity"
                        id="quote_validity_id"
                        className={`${style.selectInput} text-primary fw-bold ${
                          this.state.addAuction.status === "solved"
                            ? ownStyles.inputDisabled
                            : ""
                        } w-100 text-primary`}
                        onChange={this.onChangeForm}
                        onBlur={this.onBlurInputDate}
                        value={this.state.addAuction.quote_validity}
                        disabled={
                          this.state.addAuction.status === "solved"
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className={`${style.selectGroup} col-2`}>
                      <label
                        htmlFor="quotation_number"
                        className={`${style.labelFont}`}
                      >
                        Número de cotización
                      </label>
                      <input
                        type="text"
                        name="quotation_number"
                        className={`text-darkGray ${style.selectInput} ${
                          this.state.addAuction.status === "solved"
                            ? ownStyles.inputDisabled
                            : ""
                        } w-100`}
                        readOnly
                        disabled={
                          this.state.addAuction.status === "solved"
                            ? true
                            : false
                        }
                        value={
                          this.state.addAuction.quotation_number
                        }
                      />
                    </div>
                    <div className={`${style.selectGroup} col-2`}>
                      <label
                        htmlFor="pay_conditions"
                        className={`${style.labelFont}`}
                      >
                        Condiciones de pago*
                      </label>
                      <select
                        name="pay_conditions"
                        id="pay_conditions"
                        className={`text-darkGray ${style.selectInput} ${
                          this.state.addAuction.status === "solved"
                            ? ownStyles.inputDisabled
                            : ""
                        } w-100`}
                        onChange={this.onChangeForm}
                        value={this.state.addAuction.pay_conditions}
                        disabled={
                          this.state.addAuction.status === "solved"
                            ? true
                            : false
                        }
                      >
                        <option value=""></option>
                        <option value="prepayment">Pago anticipado</option>
                        <option value="pay20">Pago contraentrega</option>
                        <option value="pay20">Pago a 20 días</option>
                        <option value="pay30">Pago a 30 días</option>
                        <option value="pay60">Pago a 60 días</option>
                        <option value="pay90">Pago a 90 días</option>
                      </select>
                    </div>                    
                  <div className={ownStyles.groupIcons}>
                    <div className={ownStyles.discountInput}>
                      <NumberFormat
                        suffix="%"
                        placeholder="Descuento %"
                        disabled={this.state.addAuction.status === "solved"}
                        value={this.state.addAuction?.discount}
                        className={`${ownStyles.discountInputText} 
                            ${
                              this.state.collapsedDiscount
                                ? ownStyles.collapsedDiscount
                                : ownStyles.expandDiscount
                            }`}
                        onValueChange={(values) => {
                          let { addAuction, selectedListArticles } = this.state;
                          if (values.value >= 0 && values.value <= 100) {
                            addAuction.discount = values.value;
                            addAuction?.articles?.forEach((element) => {
                              if (selectedListArticles.includes(element.id)) {
                                element.discount = values.value;
                                if (element.unit_price && element.qty_article) element.discount_value = (values.value / 100) * ((element.unit_price * element.qty_article) + element.iva_value);                                
                              }
                            });
                          }else{
                            addAuction.discount = "";
                            responseSwal({icon:"error", title:"Error", text:"Valor inválido"})
                          }
                          this.setState({ addAuction });
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={Discount}
                        alt="Descuento"
                        width="22"
                        className={ownStyles.actionsIcons}                                               
                        onClick={
                          this.state.selectedListArticles.length > 0 ?
                          () => 
                          this.setState({
                            ...this.state,
                            collapsedDiscount: !this.state.collapsedDiscount,
                          })
                          : null
                        }
                      />
                    </div>
                    <img
                      src={Trash}
                      alt="Borrar"
                      width="18"
                      className={ownStyles.actionsIconsTrash}
                      onClick={this.deleteArticles}
                      style={{marginBottom:"3.5px"}}                      
                    />
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{margin:"0 1.8rem"}}>
              <GenericTableNew
                headers={renderHeaders}
                dark={false}
                lowerCase={true}
                footer={renderFooter}
              >
                {renderArticles}
              </GenericTableNew>
            </div>
            <div className="d-flex justify-content-between" style={{marginTop:"-100px"}}>
              <div className="d-flex w-100" style={{marginLeft:"1.8rem"}}>
                <div className={`${ownStyles.containerDropzone} w-25`}>
                  <Dropzone noClick={true}>
                    {({ getRootProps, getInputProps }) => (
                      <section
                        className={ownStyles.dropzoneSignature}
                        style={{
                          backgroundImage: `url(${this.props.provider[0].signature})`,
                          border: "2px solid #005DBF",
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <div {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            type="file"
                            name="signature_stamp"
                            accept={ACCEPT_IMAGES}
                            multiple={false}
                          />
                          <label
                            htmlFor="signature_stamp"
                            className={`text-primary text-center font-size-label px-5 pt-3 pb-5 hoverPointer`}
                          ></label>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  <h6 className={style.signatureLabel}>
                    Firma y/o Sello del Proveedor
                  </h6>
                </div>
                <div className="ml-3 d-flex flex-column w-40">
                  <label htmlFor="observations" className={style.labelFont}>
                    Observaciones
                  </label>
                  <textarea
                    name="observations"
                    cols="30"
                    rows="10"
                    className={ownStyles.observationsArea}
                    onChange={this.onChangeForm}
                    value={this.state.addAuction?.observations}
                    disabled={
                      this.state.addAuction.status === "solved" ? true : false
                    }
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mx-3">
              <Link
                to={`/proveedores/cotizaciones`}
                className="btn btn-outline-clear-blue"
              >
                Cancelar
              </Link>
              {this.state.addAuction.status !== "solved" && (
                <button
                  className={
                    disabledSend
                      ? `btn ${ownStyles.buttonDisabled} mx-3`
                      : "btn btn-primary mx-3"
                  }
                  onClick={(e) => this.handleSubmit(e)}
                  disabled={disabledSend}
                >
                  Enviar cotización
                </button>
              )}
            </div>
          </div>
        </div>        
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isAuth: state.providerReducer.Authenticated,
  provider: state.providerReducer.providerSession,
  listAuctions: state.auctionReducer.listAuctions,
  accounts: state.accountReducer.accounts,
  totalIndex: state.auctionReducer.totalIndex,
  clients: state.auctionReducer.clients,
  auctionArticles: state.auctionReducer.auctionArticles,
  totalArticles: state.auctionReducer.totalArticles,
});

const mapDispatchToProps = (dispatch) => ({
  getAuctions: (filters) => {
    dispatch(getAuctions(filters));
  },
  getAllAccounts: () => {
    dispatch(getAllAccounts());
  },
  getAuctionsClients: (id_provider, params) => {
    dispatch(getAuctionsClients(id_provider, params));
  },
  getAuctionArticles: (id_quotation, params) => {
    dispatch(getAuctionArticles(id_quotation, params));
  },
  sendQuotation: (objQuotation, executeAfter) => {
    dispatch(sendQuotation(objQuotation, executeAfter));
  },
  cleanAuctionList: () => {
    dispatch({
      type:GET_AUCTIONS,
      payload:[]
    })
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(QuotationDetails);
