//actions
import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { responseSwal } from "../helpers/responseSwal";
import {
  LOADING,
  GET_PROVIDERS,
  GET_COUNTRIES,
  GET_CIIU,
  GET_PROVIDER_CLASSIFICATION,
  CREATE_DRAFT_PROVIDER,
  GET_EDIT_PROVIDER,
  GET_BANKING_ENTITIES,
  LOGIN_PROVIDER_SUCCESS,
  PROVIDER_AUTH,
  GET_PURCHASEORDERSTATUS,
  GET_LIST_INVOICES
} from "./actionTypes";
import { convertFilterToString } from "../helpers/convertToFilter";

export const getProviders = (params) => (dispatch, getState) => {
  dispatch({
    type: LOADING,
    payload: true,
  });
  const token = getState().loginReducer.Authorization;
  const id_account = getState().loginReducer.currentAccount.id;
  params.id_account = id_account;
  const filters = convertFilterToString(params);
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/provider?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: LOADING,
        payload: false,
      });
      dispatch({
        type: GET_PROVIDERS,
        payload: res.results,
        row_total: res.row_total,
        total_index: res.total_index,
      });
    })
    .catch(() => {
      dispatch({
        type: LOADING,
        payload: false,
      });
      responseSwal({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error al traer la información de proveedores",
      });
    });
};

export const getCountries = (params) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  // const id_account = getState().loginReducer.currentAccount.id;
  const filters = convertFilterToString(params);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/countries?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_COUNTRIES,
        payload: res.results,
      });
    });
};

export const getCIIU = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/ciiu`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_CIIU,
        payload: res.results,
      });
    });
};

export const getProviderClassifications = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/provider/classification`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_PROVIDER_CLASSIFICATION,
        payload: res.results,
      });
    });
};

export const cleanDrafts = () => (dispatch) => {
  dispatch({
    type: CREATE_DRAFT_PROVIDER,
    payload: null,
  });
};

export const getEditProvider = (provider) => (dispatch) => {
  dispatch({
    type: GET_EDIT_PROVIDER,
    payload: provider,
  });
};

export const getBankingEntities = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/bankingEntities`, {
    headers: {
      "Conent-type": "application/json",
      accept: 'application/json',
      Authorization: token
    }
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_BANKING_ENTITIES,
        payload: res.results
      })
    })
}
export const validateProvider = (nit, password) => (dispatch) => {
  if (!nit || !password) {
    return responseSwal({
      icon: 'error',
      title: 'Error',
      text: "Datos incorrectos"
    })
  }
  let data = { nit: nit, password: password }
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/provider/login`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then((response) => response.json())
    .then(res => {
      if (res.success) {
        dispatch({
          type: PROVIDER_AUTH,
          payload: true,
          token: res.auth
        })
        dispatch({
          type: LOGIN_PROVIDER_SUCCESS,
          payload: res.results
        })
      } else {
        return responseSwal({
          icon: "error",
          title: "Error",
          text: `${res.message}`
        })
      }
    })
    .catch(() => {
      return responseSwal({
        icon: "error",
        title: "Error",
        text: "Datos inválidos"
      })
    })
}

export const logOut = () => (dispatch) => {
  dispatch({
    type: PROVIDER_AUTH,
    payload: false
  })
  dispatch({
    type: LOGIN_PROVIDER_SUCCESS,
    payload: null
  })
}

export const sendQuotation = (objQuotation, executeAfter) => (dispatch, getState) => {
  const token = getState().providerReducer.Authorization;
  if (!objQuotation.payConditions) {
    return responseSwal({
      icon: "error",
      title: "Error",
      text: "Debe escoger las condiciones de pago"
    })
  }
  if (!objQuotation.due_date) {
    return responseSwal({
      icon: "error",
      title: "Error",
      text: "Debe escoger una fecha de validez"
    })
  }
  if (!objQuotation.signature_stamp) {
    return responseSwal({
      icon: "error",
      title: "Error",
      text: "Debe ingresar la firma del proveedor"
    })
  }
  if (!objQuotation.signature_stamp) {
    return responseSwal({
      icon: "error",
      title: "Error",
      text: "Debe ingresar la firma del proveedor"
    })
  }

  console.log(objQuotation)

}
export const get_purchaseOrderStatus = (objFilters) => (dispatch, getState) => {
  let filters = convertFilterToString(objFilters);
  const token = getState().providerReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/purchaseOrder/purchaseOrderStatus/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Set-Cookie-Origin": "switch=prov",
      Authorization: token
    }
  }).then(response => response.json())
    .then(res => {
      const { results, row_total } = res

      dispatch({
        type: GET_PURCHASEORDERSTATUS,
        results: results,
        row_total: row_total
      })
    })
    .catch((err) => {
      console.error(err)

    })
}
export const getListInvoices = (objFilters) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/treasury/pinvoices/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_LIST_INVOICES,
        payload: results,
        total: total,
      });
    })
    .catch((err) => {
      console.error(err.message);
      return responseSwal({
        icon: "error",
        title: "Error al consultar",
        text: "No se pudo traer los registros",
        footer: "Si el problema persiste comuníquese con un asesor.",
      })
    });
};
