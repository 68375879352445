import React, { Component } from "react";
import { connect } from "react-redux";
import HeaderProviders from "./headerProviders";
import "../../index.scss";
import { validateProvider } from "../../actions/providersActions";
import NumberFormat from "react-number-format";
import style from "./providerInterface.module.scss";
import PersonIcon from "../../assets/img/icons/PersonIcon.svg"
import candado from "../../assets/img/icons/lockPassword.svg"
import { Redirect } from "react-router-dom";
class LoginProviders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nit: "",
      password: "",
    };
  }


  componentDidMount(){
  }

  


  handleSubmit = (e) => {
    e.preventDefault();
    let { nit } = this.state;
    let { password } = this.state;
    this.props.validateFieldsLogin(nit, password);
  };
  render() {    
    // Redirect if already logged in    
    if (this.props.isAuth && this.props.provider) {
      return <Redirect to={`/proveedores/inicio`} />
    }
    return (
      <div className={style.containerBackground}>
        <HeaderProviders/>
        <div className={style.containerLogin}>
          <h3 className="title-blue p-3 fw-bold">Portal Proveedores</h3>          
          <form id="formLogin" onSubmit={this.handleSubmit}>
          <div className={style.containerInputLogin}>
            <img src={PersonIcon} alt="icono usuario" width="18"/>
            <NumberFormat 
                allowNegative={false}
                placeholder="NIT"
                className={style.inputLogin}
                onValueChange={(values) => {
                    this.setState({...this.state, nit:values.value})
                }}
            />
          </div>
          <div className={style.containerInputLogin}>
            <img src={candado} alt="icono usuario" width="18"/>
            <input
              type="password"              
              className={style.inputLogin}
              placeholder="CONTRASEÑA"
              onChange={(e)=>this.setState({...this.state, password:e.target.value})}/>
          </div>
            <button className={style.btnLogin} type="submit">
              acceder
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, /*ownProps*/) => ({
  isAuth: state.providerReducer.Authenticated,    
  provider: state.providerReducer.providerSession,    
});

const mapDispatchToProps = (dispatch) => ({
  validateFieldsLogin: (nit, password) => {
    dispatch(validateProvider(nit, password));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginProviders);
