import {
  GET_PROVIDERS,
  LOADING,
  GET_COUNTRIES,
  GET_CIIU,
  GET_PROVIDER_CLASSIFICATION,
  CREATE_DRAFT_PROVIDER,
  GET_EDIT_PROVIDER,
  GET_BANKING_ENTITIES,
  LOGIN_PROVIDER_SUCCESS,
  PROVIDER_AUTH,
  GET_PURCHASEORDERSTATUS,
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  providers: [],
  countries: [],
  row_total: 0,
  total_index: 0,
  ciiu: [],
  providerClassification: [],
  providerDraft: null,
  providerEdit: null,
  bankingEntities: [],
  providerSession: null,
  Authenticated: false,
  Authorization: null,
};
export const providerReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };

    case GET_PROVIDERS:
      return {
        ...state,
        providers: action.payload,
        row_total: action.row_total,
        total_index: action.total_index,
      };
    case GET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case GET_CIIU:
      return {
        ...state,
        ciiu: action.payload,
      };
    case GET_PROVIDER_CLASSIFICATION:
      return {
        ...state,
        providerClassification: action.payload,
      };
    case CREATE_DRAFT_PROVIDER:
      return {
        ...state,
        providerDraft: action.payload,
      };
    case GET_EDIT_PROVIDER:
      return {
        ...state,
        providerEdit: action.payload,
      };
    case GET_BANKING_ENTITIES:
      return {
        ...state,
        bankingEntities: action.payload,
      };
    case LOGIN_PROVIDER_SUCCESS:
      return {
        ...state,
        providerSession: action.payload,
      };

    case PROVIDER_AUTH:
      return {
        ...state,
        Authenticated: action.payload,
        Authorization: action.token
      }
    case GET_PURCHASEORDERSTATUS:
      return {
        ...state,
        po: action.results,
        po_total: action.row_total,
      };
    default:
      return state;
  }
};
